html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  color: $body-color;
  font-size: $body-font-size-mobile;

  @include media-breakpoint-up(md) {
    font-size: $body-font-size-desktop;
  }
}

.main {
  overflow-x: hidden;
}


h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 1.3;
  font-family: $heading-font;

  &:last-child {
    margin-bottom: 0;
  }
}

h1,
.h1 {
  font-size: 30px;
  line-height: 1.04;
  letter-spacing: -.46px;

  @include media-breakpoint-up(md) {
    font-size: 45px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 57px;
  }
}

h2,
.h2 {
  font-size: 28px;
  line-height: 1.04;

  @include media-breakpoint-up(md) {
    font-size: 30px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 34px;
  }
}

h3,
.h3 {
  font-size: 20px;

  @include media-breakpoint-up(lg) {
    font-size: 22px;
  }
}

h4,
.h4 {
  font-size: 16px;

  @include media-breakpoint-up(lg) {
    font-size: 20px;
  }
}

h5,
.h5 {}

h6,
.h6 {
  font-size: 16px;
}

.title {
  font-size: 28px;
  line-height: 1.23;
  letter-spacing: -.2px;

  @include media-breakpoint-up(md) {
    font-size: 36px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 44px;
  }
}

.title-md {
  font-size: 24px;
  line-height: 1.26;
  letter-spacing: -.3px;

  @include media-breakpoint-up(md) {
    font-size: 26px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 30px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 38px;
  }
}

button,
a {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: $orange500;

  &:hover,
  &:focus {
    text-decoration: none;
    color: darken($orange500, 10%);
  }
}

p {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.text-semiwhite {
  opacity: .5;
}

.img-negative-right {
  @include media-breakpoint-down(md) {
    transform: translateX($global-space)
  }
}

.text-md {
  font-size: 14px;

  @include media-breakpoint-up(md) {
    font-size: 18px;
  }
}

.text-small {
  font-size: 14px;

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
}

.text-xs {
  font-size: 12px;
  letter-spacing: -0.2px;

  @include media-breakpoint-up(md) {
    font-size: 14px;
  }
}

.min-width-0 {
  min-width: 0;
}

.containt-icon {
  font-size: 55px;

  @include media-breakpoint-up(md) {
    font-size: 60px;
    margin-bottom: 30px;
  }

  &-md {
    @include media-breakpoint-up(md) {
      font-size: 70px;
      margin-bottom: 30px;
    }
  }
}

.ls-negative {
  letter-spacing: -.2px;
}

.link-back {
  @extend .fs-15,
  .text-primary,
  .font-secondary-bold;

  &:hover,
  &:focus,
  &:active {
    @extend .text-orange500;
  }
}