/* HERO FAQ */

.hero-faq {
    @extend .position-relative,
    .bg-orange500,
    .text-white;
    z-index: 10;
    padding: 24px 0 25% 0;

    @media (min-width: 450px) {
        padding: 24px 0 50% 0;
    }

    @include media-breakpoint-up(md) {
        padding: 90px 0;
    }

    @include media-breakpoint-up(xl) {
        padding: 120px 0;
    }

    &:before {
        @extend .position-absolute,
        .end-0,
        .bottom-0,
        .bg-white;

        @include media-breakpoint-up(md) {
            content: '';
            z-index: -1;
        }

        @include media-breakpoint-up(md) {
            width: 35%;
            height: 100px;
        }

        @include media-breakpoint-up(lg) {
            height: 115px;
        }

        @include media-breakpoint-up(xl) {
            height: 155px;
        }

        @include media-breakpoint-up(xxl) {
            height: 180px;
        }
    }

    h1 {
        letter-spacing: -1px;
    }
}



.hero-faq__img-desktop,
.hero-faq__img-mobile {
    @extend .position-absolute,
    .end-0;
}

.hero-faq__img-mobile {
    @extend .bottom-0;

    max-width: 220px;

    @media (min-width: 768px) {
        height: 100%;
        object-fit: cover;
    }
}

.hero-faq__img-desktop {
    max-width: 499px;

    @include media-breakpoint-up(md) {
        bottom: -90px;
        max-width: 480px;
    }

    @include media-breakpoint-up(lg) {
        max-width: 540px;
    }

    @include media-breakpoint-up(xl) {
        bottom: -120px;
        max-width: 680px;
        margin-right: 80px;
    }

    @include media-breakpoint-up(xxl) {
        max-width: 730px;
    }
}