/* HEADER */

.header {
    @extend .flex-shrink-0;

    >.container {
        @include media-breakpoint-down(lg) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}

.header-logo {
    @extend .d-block,
    .flex-shrink-0,
    .me-4;
    width: 118px;

    @include media-breakpoint-up(lg) {
        width: 205px;
    }
}

.header-top {
    @extend .d-flex,
    .align-items-center,
    .justify-content-between;
    padding-top: 12px;
    padding-bottom: 13px;

    @include media-breakpoint-up(lg) {
        padding-top: 16px;
        padding-bottom: 16px;
    }
}

.header-top-nav-box {
    @extend .d-none,
    .d-lg-flex,
    .flex-column,
    .flex-lg-row,
    .align-items-lg-center;

    @include media-breakpoint-down(lg) {
        z-index: 400;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        background-color: $white;
        padding: 42px 28px;
    }


    &.header-top-nav-box--is-opened {
        @extend .d-flex,
        .w-100,
        .h-100;
    }

    .header-language-box {
        @include media-breakpoint-up(lg) {
            margin-left: 30px;
            margin-right: 20px;
        }

        @include media-breakpoint-up(xxl) {
            margin-left: 55px;
            margin-right: 22px;
        }
    }
}

.header-top-nav {
    @extend .d-flex,
    .flex-column,
    .flex-lg-row;

    @include media-breakpoint-down(lg) {
        margin-bottom: 30px;
    }
}

.header-top-nav-item {
    @extend .text-primary,
    .py-lg-0,
    .position-relative;
    font-size: 24px;
    padding-top: 13px;
    padding-bottom: 13px;
    font-family: $font-secondary-bold;

    @include media-breakpoint-up(lg) {
        font-size: 17px;
        font-family: $font-secondary-extrabold;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
        @extend .text-orange500;

        @include media-breakpoint-up(lg) {
            color: $green600 !important;
        }
    }

    &.active {
        @include media-breakpoint-up(lg) {
            &:after {
                opacity: 1;
            }
        }
    }

    &:after {
        @extend .position-absolute,
        .start-50,
        .translate-middle-x,
        .bg-green600;
        opacity: 0;
        width: 26px;
        height: 3px;
        bottom: -3px;

        @include media-breakpoint-up(lg) {
            content: '';
        }
    }

    &+.header-top-nav-item {
        @include media-breakpoint-up(lg) {
            margin-left: 20px;
        }

        @include media-breakpoint-up(xxl) {
            margin-left: 40px;
        }
    }
    &.nav-item-page-credit { 
        display: none;
    }
}

.header-language-box {
    @extend .align-items-center,
    .fs-13;

    .dropdown-toggle {
        padding-top: 5px !important;
        @extend .shadow-none,
        .p-0,
        .border-0,
        .fs-13,
        .text-uppercase;

        &:after {
            content: none;
        }
    }

    .icon {
        @extend .flex-shrink-0,
        .text-gray1300,
        .me-2,
        .d-inline-block;
    }
}

.header-language-select {
    @extend .form-select,
    .border-0,
    .shadow-none,
    .p-0,
    .text-gray1300,
    .font-secondary-bold,
    .text-uppercase,
    .fs-13;
    cursor: pointer;
    background-image: none;
}

.btn-top-nav {
    @extend .fs-15;
    padding: 7.75px 26px;
}

.header-bottom {
    border-top: 1px solid $gray300;
    @extend .d-none,
    .d-lg-block;
    padding-top: 12px;
}

.header-bottom-nav {
    @extend .d-flex,
    .justify-content-between;
}

.header-bottom-nav-item {
    @extend .fs-15,
    .font-secondary-semibold,
    .text-gray1500;
    letter-spacing: -.2px;
    padding-bottom: 9px;
    border-bottom: 3px solid #fff;

    &:hover,
    &:focus,
    &:active {
        @extend .text-orange500;
    }

    &.active {
        color: #0F6718 !important;
        border-bottom: 3px solid #0F6718;
    }
}

.header-top-nav-box-open,
.header-top-nav-box-close {
    @extend .border-0,
    .bg-transparent,
    .d-block,
    .position-relative,
    .p-0;
}

.header-top-nav-box-open {
    width: 22px;
    height: 12px;

    &:before,
    &:after {
        content: '';
        @extend .position-absolute,
        .w-100,
        .bg-primary;
        height: 2px;
        border-radius: 2px;
        left: 0;
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 0;
    }
}

.header-top-nav-box-close {
    width: 22px;
    height: 22px;

    &:before,
    &:after {
        content: '';
        @extend .position-absolute,
        .w-100,
        .bg-primary;
        height: 2px;
        border-radius: 2px;
        left: 0;
        top: 50%;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
}

.header-top-mobile-header {
    @extend .d-flex,
    .align-items-center,
    .justify-content-between,
    .d-lg-none,
    .mb-4;
}

.header-top-mobile-header-logo {
    @extend .d-block;
    width: 159px;
}

.header-top-nav-subnav {
    @extend .d-none,
    .fixed-top,
    .w-100,
    .h-100,
    .overflow-auto,
    .bg-white;
    z-index: 410;
    padding: 49px 28px 42px 28px;

    &.header-top-nav-subnav--is-opened {
        @extend .d-block;
    }
}

.header-top-nav-subnav-nav {
    display: grid;
    grid-gap: 24px;
}

.header-top-nav-subnav-nav-item {
    @extend .fs-18,
    .text-primary,
    .font-secondary-bold,
    .d-flex,
    .align-items-start;

    &:hover,
    &:focus,
    &:active {
        @extend .text-orange500;
    }

    .icon {
        @extend .flex-shrink-0;
        margin-right: 5px;
        width: 38px;
        margin-top: 4px;
    }

    &::before {
        content: '';
        display: inline-block;
        width: 26px;
        height: 18px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin-top: 3px;
        margin-right: 15px;
    }

    &__consumer::before {
        width: 24px;
        margin-left: 2px;
        background-image: url('/static/img/kredits/ic-consumer.svg');
    }
    &__renovation::before {
        background-image: url('/static/img/kredits/ic-renovation.svg');
    }
    &__merge::before {
        background-image: url('/static/img/kredits/ic-merge.svg');
    }
    &__car::before {
        background-image: url('/static/img/kredits/ic-car.svg');
    }
    &__collateral::before {
        background-image: url('/static/img/kredits/ic-collateral.svg');
    }
    &__realestate::before {
        background-image: url('/static/img/kredits/ic-realestate.svg');
    }
    &__company::before {
        background-image: url('/static/img/kredits/ic-company.svg');
    }
    &__refinancing::before {
        background-image: url('/static/img/kredits/ic-refinancing.svg');
    }
    &__loan::before {
        width: 24px;
        margin-left: 2px;
        background-image: url('/static/img/kredits/ic-loan.svg');
    }
    &__fast_loan::before {
        height: 20px;
        width: 22px;
        margin-left: 3px;
        margin-right: 17px;
        background-image: url('/static/img/kredits/ic-fast_loan.svg');
    }
    &__creditline::before {
        width: 20px;
        height: 18px;
        margin-left: 4px;
        margin-right: 17px;
        background-image: url('/static/img/kredits/ic-creditline.svg');
    }
}

.header-scroll {
    position: fixed;
    transition: 0.1s ease;
    width: 100%;
    height: 4rem;
    top: 0;
    z-index: 10000;
    background: rgba(255,255,255,0.92);
    box-shadow: 0px 1px 1px rgba(0,0,0,0.08);
    backdrop-filter: blur(3px);
    border-bottom: none;

    &.header-full {
      height: 120px;
      > div .header-bottom {
        display: block !important;
      }
    }
    
    > div .header-top {
      height: 4rem; 
      border-bottom: 0;
    } 
    > div .header-bottom {
      display: none !important;
    }

    .header-language-box {
        margin-left: 22px;
    }

    .header-top-nav-item {
        @include media-breakpoint-up(lg) {
            margin-left: 10px;
        }

        @include media-breakpoint-up(xxl) {
            margin-left: 18px;
        }
    }
      .nav-item-page-credit {
        @extend 
        .btn,
        .d-flex,
        .shadow-none,
        .font-icons;
        font-weight: 500;
        font-size: 17px !important;
        font-family: Nunito-ExtraBold;
        padding-left: 0 !important;
        margin: 0 !important;
  
        &:before {
          @extend 
          .position-absolute,
          .end-0,
          .top-50,
          .translate-middle-y,
          .icon-chevron-down;
          font-size: 80%;
        }
        &.active {
          @extend 
          .icon-chevron-up;
        }
        &:after { 
            background-color: none !important;
            --bs-bg-opacity: 0 !important;
        }
    }
      @media (max-width: 1400px) { 
        transition: 0.3s ease;
        height: 3rem;
          > div .header-top { 
        height: 3rem;
        }
        .header-language-box {
            margin-left: none;
        }
        .container {
            padding-left: 1rem !important;
            padding-right: 1rem !important;
        }
    }
  }

  .header-scroll-block {
    height: 7.90625rem !important;
    display: none;
    @media (max-width: 1400px) { 
        display:none;
        height: 3.125rem !important;
    }
}