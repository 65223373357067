/* STATS */

.stats {
    @extend .font-secondary-bold;
    display: flex;
    flex-flow: column;

    @include media-breakpoint-up(md) {
        justify-content: space-between;
        flex-flow: row;
        font-size: 22px;
    }
}

.stats-column {
    .stat {
        margin-bottom: 36px;
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            &:first-child {
                margin-bottom: 70px;
            }
        }
    }
}

.stat-title {
    @extend .font-secondary-extrabold;
    font-size: 48px;
    line-height: 47px;
    margin-bottom: 14px;

    @include media-breakpoint-up(md) {
        font-size: 68px;
        line-height: 66px;
        margin-bottom: 20px;
    }
}

.stat-subtitle {
    font-size: 16px;
    line-height: 19px;

    @include media-breakpoint-up(md) {
        font-size: 22px;
        line-height: 26px;
    }
}