/* FORM */

$field-border-color: #b4b9c5;

.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    @extend .fs-14;
    border-color: transparent;
    border-radius: 4px;
    height: 50px;
    @include custom-placeholder($color: $gray1300,
        $font-size: 14px,
        $font-family: $font-base-regular);

    &:focus {
        box-shadow: 0 0 0 3px rgba($orange500, .25%);
        border-color: $field-border-color;
    }

    &-secondary {
        border-color: $field-border-color;
    }
}

.form-select {
    @extend .form-control,
    .font-base-medium;
}

textarea.form-control {
    resize: none;
    min-height: 152px;
}

.form-row-connected {
    border: 1px solid $field-border-color;
    border-radius: 4px;

    &.is-invalid {
        border-color: red;
    }

    .form-floating {
        &+.form-floating {
            border-top: 1px solid $field-border-color;
        }

        &:first-of-type {
            .form-control {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }
        }

        &:last-of-type {
            .form-control {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }

    .form-control {
        @extend .border-0;
        border-radius: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        height: 50px;
    }

    label {
        color: $gray1300;
        font-size: 14px;
        font-family: $font-base-regular;
        padding-top: 14px;
        padding-bottom: 14px;
    }
}

.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-control:focus~label {
    opacity: 1;
    @extend .font-base-medium;
}

.form-check-input:checked {
    background-color: $pink500;
    border-color: $pink500 !important;
}

.form-check-input:focus {
    box-shadow: 0 0 0 0.25rem rgba($pink500, .25%);
}

.form-check-input[type=checkbox] {
    border-radius: 2px;
    border-color: $field-border-color;
}