/* HERO CALCULATOR RANGE */

.hero-calculator-range {
    &+.hero-calculator-range {
        margin-top: 36px;
    }
}

.hero-calculator-range-header {
    @extend .d-flex,
    .align-items-center,
    .justify-content-between,
    .mb-3;
}

.hero-calculator-range-header-title {
    @extend .fs-18,
    .font-secondary-bold,
    .text-truncate;
}

.hero-calculator-range-header-value {
    @extend .flex-shrink-0,
    .ms-4;
}

.hero-calculator-range-header-value {
    font-size: 28px;
    @extend .font-secondary-extrabold,
    .me-1;
    line-height: 1.05;
}

.hero-calculator-range-header-value-name {
    @extend .fs-18,
    .font-secondary-bold;
}