/* cash-loan-box */

.cash-loan-box {
    @extend .bg-pink500,
    .position-relative,
    .flex-fill,
    .text-white;
    font-size: 14px;

    @include media-breakpoint-down(md) {
        padding: 40px 50px;
        margin-left: -$global-space;
        margin-right: -$global-space;
    }

    @include media-breakpoint-up(md) {
        padding: 50px 40px;
        font-size: 16px;
    }

    @include media-breakpoint-up(xl) {
        padding: 90px 40px;
    }

    @include media-breakpoint-up(xxl) {
        padding: 70px 92px;
    }

    &:before {
        @include media-breakpoint-up(md) {
            content: '';
        }

        width: 200%;
        @extend .bg-pink500,
        .position-absolute,
        .bottom-0,
        .h-100;
        right: -100%;
        z-index: -1;
    }
}