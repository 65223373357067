/* HERO INFO */

.hero-info {
    @extend .bg-green200,
    .text-white;
    padding: 35px 0;

    @include media-breakpoint-up(md) {
        padding: 55px 0;
    }
}