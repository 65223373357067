/* advantages-of-choices */

.advantages-of-choices {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 35px;
    grid-row-gap: 18px;

    @include media-breakpoint-up(md) {
        padding-bottom: 20px;
    }

    @include media-breakpoint-up(xl) {
        padding-bottom: 40px;
        grid-column-gap: 90px;
    }
}

.advantages-of-choice {
    @extend .position-relative,
    .font-secondary-semibold;
    font-size: 20px;
    padding-top: 20px;
    line-height: 24px;

    @include media-breakpoint-up(lg) {
        font-size: 26px;
    }

    &:before {
        content: '';
        @extend .position-absolute,
        .start-0,
        .top-0,
        .bg-primary;
        width: 51px;
        height: 3px;
    }
}

.advantages-of-choice-box {
    @include media-breakpoint-down(md) {
        background-color: $yellow500;
        padding: 40px 25px;
        margin-left: -$global-space;
        margin-right: -$global-space;
    }

    &-title {
        @extend .font-secondary-bold;
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 24px;

        @include media-breakpoint-up(md) {
            font-size: 36px;
            line-height: 38px;
            margin-bottom: 32px;
            font-family: $font-secondary-extrabold;
        }
    }
}