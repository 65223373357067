
.calculator {
  &__close-btn {
    padding: 4px!important;
    background-color: white!important;
  }

  &__close-btn-wrapper {
    position: absolute!important;
    top: 10px;
    right: 15px;
    z-index: 1;
    cursor: pointer;
    background-color: white!important;
  }

  &__blog {
    transform: none;
    display: none!important;
    position:fixed;
    right: 0!important;
    bottom: 10px;
    z-index: 20;
    max-width: 100%;
    width: 100%;
    margin: 0;
    box-shadow: 0 0 10px rgba(#d9dbdf, 0.8);
    border-radius: 6px;

    @media (min-width: 500px) {
      left: auto;
      max-width: 420px;
    }

    &.active {
      display: block!important;
    }
    
    .hero-calculator {
      width: 100%;
      border: none;
    }

    .hero-calculator-form {
      margin: 0;
    }

    .hero-calculator-form-header {
      @extend .text-white;
    }

    .hero-calculator-body {
      padding-top: 35px;
    }
  }

  &__blog-toggle-btn {
    z-index: 10;
    right: 0;
    bottom: 25px;
    position: fixed;
    padding: 10px;
    margin-right: 10px;
    background: white;
    border-radius: 6px;
    color: #502CC0;
    display: flex;
    align-items: center;
    box-shadow: 0 0 10px rgba(#d9dbdf, 0.8);

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 111.111% */
    letter-spacing: -0.2px;
    transition: all 0.3s ease;

    &:hover {
      path {
        fill: #fd7823;
      }
    }

    svg {
      width: 40px;
      height: 25px;
      margin-right: 5px;

      path {
        fill: #502CC0;
        transition: fill 0.3s ease;
      }
    }
  }
}