/* LIST CHECK */

.list-check {
    display: grid;
    grid-gap: 5px;
}

.list-check-item {
    @extend .d-flex,
    .align-items-center;

    img {
        margin-right: 10px;
    }
}