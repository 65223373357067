/* LOAN TYPES */

.loan-types-accordion-item {
    border-bottom: 1px solid $gray1800;
    padding-top: 13px;
    padding-bottom: 13px;

    &:first-of-type {
        border-top: 1px solid $gray1800;
    }

    .list-dash-item {
        @extend .fs-12;
    }
}

.loan-types-accordion-button {
    @extend .btn,
    .border-0,
    .py-0,
    .ps-0,
    .pe-4,
    .d-flex,
    .align-items-start,
    .justify-content-start,
    .text-start,
    .position-relative,
    .shadow-none,
    .w-100,
    .font-icons,
    .icon-chevron-down;

    &[aria-expanded="false"] {
        @extend .icon-chevron-down;
    }

    &[aria-expanded="true"] {
        @extend .icon-chevron-up;
    }

    .accordion-title {
        line-height: 1;
        letter-spacing: -0.2px;
    }

    &:before {
        @extend .position-absolute,
        .end-0;
        top: -3px;
    }
}

.loan-types-accordion-button-icon {
    @extend .flex-shrink-0,
    .me-4;
    height: 26px;
    width: 32px;
    img {
        width: 100%;
        height: 100%;
    }
}

.loan-types-accordion-body {
    padding-left: 50px;
}