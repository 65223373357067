/* POST GALLERY */

.posts-gallery-box {
    @extend .bg-green200,
    .position-relative;
    padding-bottom: 20px;

    @include media-breakpoint-down(md) {
        margin-left: -$global-space;
        margin-right: -$global-space;
        padding-left: $global-space;
        padding-right: $global-space;
    }

    @include media-breakpoint-up(md) {
        padding-bottom: 50px;
    }

    &:before {
        @include media-breakpoint-up(md) {
            content: '';
        }

        width: 200%;
        @extend .bg-green200,
        .position-absolute,
        .bottom-0,
        .h-100;
        right: -100%;
        z-index: -1;
    }

    &:after {
        @include media-breakpoint-down(md) {
            content: '';
        }

        @extend .position-absolute,
        .start-0,
        .w-100,
        .bg-white;
        top: -1px;
        height: 50px
    }
}

.posts-gallery-content {
    @extend .text-white;
    padding-top: 25px;

    @include media-breakpoint-up(md) {
        padding-top: 60px;
    }
}

.posts-gallery-pagination {
    transform: translateX(0) !important;
    left: 0 !important;

    .swiper-pagination-bullet {
        width: 14px;
        height: 14px;
        background-color: #5fa826;
        opacity: 1 !important;
        transform: scale(1) !important;

        &.swiper-pagination-bullet-active {
            @extend .bg-white;
        }
    }
}

.posts-gallery {
    overflow: visible !important;
}

.post-gallery-img {
    @include media-breakpoint-down(md) {
        //transform: translateX(25px) !important;
        width: 110% !important;
    }

    @include media-breakpoint-up(md) {
        transform: scale(1.1);
        transform-origin: bottom right;
    }

    @include media-breakpoint-up(lg) {
        transform: scale(1.05);
    }

    @include media-breakpoint-up(xl) {
        transform: scale(1.1);
    }

    @include media-breakpoint-up(xxl) {
        transform: scale(1.25);
    }
}