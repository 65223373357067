/* BUTTONS */

.btn {
    @extend .font-secondary-bold;
    border-radius: 6px;
    padding: 10.5px 20px;
    font-size: 16px;

    @include media-breakpoint-up(md) {
        font-size: 18px;
    }

    &-secondary-outline {
        @include button-outline-variants($color: $primary)
    }

    &-outline-white {
        @include button-outline-variants($color: $white);

        &:hover,
        &:focus,
        &:active {
            color: $pink500 !important;
        }
    }

    &-outline-green {
        @include button-outline-variants($color: $white);

        &:hover,
        &:focus,
        &:active {
            color: $green200 !important;
        }
    }

    &-orange {
        @include button-variants($bg: $orange500, $color: $white);
    }

    &-long {
        @include media-breakpoint-up(md) {
            padding-left: 36px;
            padding-right: 36px;
        }

        @include media-breakpoint-up(lg) {
            padding-left: 50px;
            padding-right: 50px;
        }

        @include media-breakpoint-up(xl) {
            padding-left: 76px;
            padding-right: 76px;
        }
    }

    &-mobile-full {
        @include media-breakpoint-down(md) {
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
        }
    }

    &-full-width {
        width: 100%;
    }

    &-social {
        @extend .bg-white,
        .d-inline-flex,
        .align-items-center,
        .justify-content-center,
        .px-3,
        .py-0;
        border: 1px solid $primary;
        min-height: 40px;

        >.btn-social-img {
            @extend .w-100;
            max-width: 90px;

            @include media-breakpoint-down(md) {
                max-width: 115px;
            }
        }
    }
}