/* FOOTER */

.footer-top {
    @extend .bg-dark400,
    .text-gray700;
    padding: 30px 0;

    @include media-breakpoint-up(md) {
        padding: 72px 0 30px 0;
    }
}

.footer-bottom {
    @extend .bg-dark600,
    .text-gray1400;
    font-size: 11px;
    padding: 30px 0;

    @include media-breakpoint-up(md) {
        font-size: 10px;
        padding: 36px 0;
    }
}

.footer-logo {
    @extend .d-block;
    width: 154px;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
        width: 176px;
    }
}

.footer-address, .footer-unsubscribe {
    @extend .font-secondary-bold,
    .d-flex,
    .flex-column;
    font-size: 25px;
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
        font-size: 20px;
        margin-bottom: 30px;
    }

    a {
        @extend .text-white,
        .d-inline-flex,
        .align-items-center;

        &:hover,
        &:focus,
        &:active {
            @extend .text-orange500;
        }
    }

    .icon {
        @extend .text-center,
        .d-block;
        font-size: 80%;
        width: 30px;
    }
}

.footer-unsubscribe {
    margin-top: 10px;

    @include media-breakpoint-up(md) {
        margin-top: 30px;
    }
}

.footer-work-hours {
    font-size: 11px;

    @include media-breakpoint-up(md) {
        font-size: 12px;
    }
}

.footer-nav {
    @extend .d-none;

    @include media-breakpoint-up(md) {
        display: grid !important;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        grid-gap: 24px;
    }
}

.footer-nav-title {
    @extend .fs-13,
    .text-white,
    .font-base-medium;
}

.footer-nav-item-link {
    @extend .d-block,
    .fs-12,
    .font-base-medium,
    .text-gray700;
    padding-top: 3px;
    padding-bottom: 3px;

    &:hover,
    &:focus,
    &:active {
        @extend .text-orange500;
    }
}

.footer-logos {
    @extend .d-flex,
    .align-items-center;
}

.footer-logo-item {
    @extend .d-block;

    &--facebook {
        width: 98px;
    }

    &--eparaksts {
        width: 112px;
    }

    &+.footer-logo-item {
        margin-left: 24px;

        @include media-breakpoint-up(md) {
            margin-left: 42px;
        }
    }
}

.footer-read-more-title::before {
    @extend .text-white;
}
.footer-read-more-content {
    @extend .fs-13,
    .font-base-medium,
    .text-gray700;

    p {
        margin-bottom: 12px;
    }
}
