/* BLOG */

.blogs {
    display: grid;
    grid-gap: 16px;

    &:not(:last-child) {
        margin-bottom: 45px;

        @include media-breakpoint-up(md) {
            margin-bottom: 85px;
        }
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 27px;
    }
}

.blog {
    @extend .d-flex;

    &:hover {
        .blog-content {
            background: #F5FFF3 !important;
        }
    }
}

.blog-img-box {
    @extend .flex-shrink-0,
    .position-relative;
    width: 128px;

    @include media-breakpoint-up(md) {
        width: 215px;
    }

    img {
        object-fit: cover;
        height: 100%;
    }
}

.blog-content {
    @extend .flex-fill,
    .bg-white,
    .text-primary,
    .d-flex,
    .flex-column;
    padding: 18px;
    border: 1px solid $gray1800;
    border-left: none;

    @include media-breakpoint-up(md) {
        padding: 24px;
    }
}

.blog-content-title {
    font-size: 14px;
    letter-spacing: -0.12px;
    line-height: 16px;
    margin-bottom: 6px;

    @include media-breakpoint-up(md) {
        font-size: 23px;
        letter-spacing: -0.2px;
        line-height: 28px;
        margin-bottom: 12px;
    }
}

.blog-content-text {
    font-size: 10px;
    letter-spacing: -0.12px;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 15px;
    max-height: 45px;
    -webkit-line-clamp: 3;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
        font-size: 13px;
        letter-spacing: -0.2px;
        line-height: 19px;
        max-height: 57px;
    }
}

.blog-content-footer {
    @extend .mt-auto,
    .d-flex,
    .align-items-center,
    .justify-content-between;
}

.blog-content-date {
    @extend .text-gray1300;
    font-size: 9px;

    @include media-breakpoint-up(md) {
        font-size: 12px;
    }
}

.blog-label {
    @extend .text-white,
    .font-secondary-bold,
    .position-absolute,
    .start-0;
    font-size: 8px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    top: 8px;
    padding: 1px 7px;
    line-height: 13px;

    @include media-breakpoint-up(md) {
        font-size: 12px;
        top: 13px;
        padding: 3.5px 11px;
    }

    &.static {
        @extend .position-static;
    }
}

.blog-item-date {
    @extend .text-gray1300;

    @include media-breakpoint-down(sm) {
        font-size: 11px;
    }
}

.blog-text {
    @extend .text-gray1600;
    font-size: 15px;

    @include media-breakpoint-up(md) {
        font-size: 16px;
    }
}

.blog-item-raksti-title {
    font-size: 28px;
    line-height: 32px;

    @include media-breakpoint-up(md) {
        font-size: 24px;
        line-height: 34px;
    }
}