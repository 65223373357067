/* HERO CALCULATOR */

.hero-calculator-box {
    @extend .d-flex,
    .flex-column,
    .flex-md-row,
    .align-items-md-end,
    .mb-md-0;
    position: relative;
    z-index: 40;
    margin-bottom: 20px;
    margin-top: -79px;
    margin-left: 10px;

    @include media-breakpoint-up(md) {
        position: absolute;
        width: 670px;
        left: 50%;
        transform: translateX(-50%) translateY(calc(50% - 30px));
        bottom: 0;
    }

    @include media-breakpoint-up(lg) {
        width: 862px;
    }

    @include media-breakpoint-up(xxl) {
        left: calc(50% + 100px);
    }

    @include media-breakpoint-down(md) {
        margin-left: -$global-space;
        margin-right: -$global-space;
    }

    &:before {
        @include media-breakpoint-down(md) {
            content: '';
        }

        @extend .position-absolute,
        .w-100,
        .bg-white,
        .start-0,
        .top-0;
        z-index: -1;
        height: calc(100% - 40px);
    }
}

.hero-calculator-dropdown-item-icon {
    width: 18px;
    height: 18px;
    margin-right: 15px;
    margin-top: -3px;

    &.icon-rect {
        width: 23px;
        height: 15px;
    }
}

.dropdown-menu {
    padding: 0 !important;
    border-radius: 0 !important;
    inset: unset !important;
    transform: none !important;

    .dropdown-item {
        padding: 0.75rem 1rem !important;
        &:hover {
            color: white !important;
            background-color: #6BB729FF !important;
            border-radius: 0 !important;
            cursor: pointer;
        }
    }

    @include media-breakpoint-up(lg) {
        width: 423px !important;
        margin-top: 13px !important;
        margin-left: -41px !important;
    }

    @include media-breakpoint-down(sm) {
        margin-top: 13px !important;
        margin-left: -28px !important;
    }
}

.calculator-dropdown-item-icon {
    width: 18px;
    height: 18px;
    margin-top: -3px;

    &.icon-rect {
        width: 28px;
        height: 19px;
    }
}

.hero-calculator {
    @extend .flex-shrink-0,
    .bg-white,
    .text-primary,
    .mb-md-0;

    @include media-breakpoint-up(md) {
        width: 320px;
        border: 1px solid $gray1700;
    }

    @include media-breakpoint-up(lg) {
        width: 423px;
    }
}

.hero-calculator-header {
    padding: 15px 25px 15px 25px;
    border-bottom: 1px solid $gray1700;

    @include media-breakpoint-up(lg) {
        padding: 15px 40px 15px 40px;
    }
}

.hero-calculator-body {
    padding: 16px 25px 46px 25px;

    @include media-breakpoint-up(lg) {
        padding: 17px 40px 46px 40px;
    }
}

.calculator-dropdown-toggle {
    @extend .btn,
    .d-flex,
    .align-items-center,
    .justify-content-start,
    .p-0,
    .shadow-none,
    .font-icons,
    .icon-chevron-down,
    .position-relative,
    .w-100;
    font-size: 19px;

    @include media-breakpoint-up(md) {
        font-size: 21px;
    }

    &:before {
        @extend .position-absolute,
        .end-0,
        .top-50,
        .translate-middle-y;
        font-size: 80%;
    }

    &:after {
        content: none;
    }
}

.hero-calculator-form {
    @extend .flex-fill,
    .bg-green800;
    padding: 26px 22px;

    @include media-breakpoint-up(xl) {
        padding: 40px;
    }

    @include media-breakpoint-down(md) {
        margin-left: 10px;
        margin-right: 10px;
        border-radius: 6px;
    }

    @include media-breakpoint-between(md, xl) {
        align-self: flex-start !important;
        margin-top: -30px;
    }

    .form-control {
        height: 40px;
    }
}

.hero-calculator-form-header {
    @extend .mb-4,
    .d-flex,
    .align-items-center;
}

.hero-calculator-form-header-title {
    @extend .d-flex;
    font-size: 38px;
    line-height: .55;
    letter-spacing: -.2px;

    @include media-breakpoint-up(xs) {
        font-size: 30px;
    }

    @include media-breakpoint-up(sm) {
        font-size: 44px;
        flex-wrap: wrap;
    }

    @include media-breakpoint-up(md) {
        flex-wrap: nowrap;
    }

    @include media-breakpoint-only(md) {
        font-size: 32px;
    }

    .small {
        @extend .align-self-end,
        .mx-2;
            font-size: 18px;

        @include media-breakpoint-up(xs) {
            font-size: 14px;
        }

    }

    .icon {
        @extend .fs-14,
        .text-green100,
        .d-inline-block;
        margin-top: -8px;
    }
}

.hero-calculator-form-header-info {
    @extend .flex-shrink-0;
    font-size: 13px;
    border-left: 1px solid $white;
    margin-left: 15px;
    padding-left: 20px;

    @include media-breakpoint-up(md) {
        font-size: 14px;
        margin-left: 34px;
    }
}

.hero-calculator-form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    margin-bottom: 18px;

    @include media-breakpoint-up(md) {
        grid-column-gap: 20px;
        grid-row-gap: 3px;
    }

    .btn-submit {
        grid-column: span 2;
        margin-top: 27px;
    }

    small {
        font-size: 0.55rem !important;
        color: red;
    }
}

.hero-calculator-form-footer {
    @extend .text-white,
    .d-flex,
    .align-items-center;
    font-size: 11px;
    margin-top: 10px;

    @include media-breakpoint-up(md) {
        font-size: 12px;
    }
}

#calc-email,
#calc-phone {
    padding: 1.2rem 0.8rem 1.2rem 0.8rem;
    z-index: 2;
    position: relative;
}

.calculator-error {
    padding: 6px 3px 3px 3px;
    position: relative;
    top: -6px;
    background: #F02525;
    color: white !important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 1;
    font-size: .55rem !important;
    &.err-phone {
        margin: 0px 25px 0px 6px !important;
    }
    &.err-email {
        margin: 0 5px 0px 26px !important;
    }
}