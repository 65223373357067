/* ABOUT STATS BOX */

.about-stats-box {
    @extend .position-relative,
    .bg-pink500,
    .text-white;
    z-index: 15;

    @include media-breakpoint-down(md) {
        padding: 40px 25px 10px 25px;
        margin-left: -$global-space;
        margin-right: -$global-space;
    }

    @include media-breakpoint-up(md) {
        padding: 50px 25px;
    }

    @include media-breakpoint-up(lg) {
        padding: 50px;
    }

    @include media-breakpoint-up(xl) {
        padding: 94px;
    }

    &:before {
        @extend .position-absolute,
        .h-100,
        .bg-pink500,
        .bottom-0;

        @include media-breakpoint-up(md) {
            content: '';
            z-index: -1;
            width: 200%;
            left: 100%;
        }
    }

    .row {
        .col {
            margin-top: 0;
        }

        .col > .col {
            margin-bottom: 36px;
            @include media-breakpoint-up(md) {
                margin-bottom: 0;
                &:first-child {
                    margin-bottom: 70px;
                }
            }
        }
    }
}

.about-stat-title {
    @extend .lh-1,
    .font-secondary-extrabold;
    font-size: 48px;
    line-height: 47px;
    margin-bottom: 14px;

    @include media-breakpoint-up(md) {
        font-size: 68px;
        line-height: 66px;
        margin-bottom: 20px;
    }
}

.about-stat-text {
    font-size: 16px;
    @extend .font-secondary-bold;
    line-height: 19px;

    @include media-breakpoint-up(md) {
        font-size: 22px;
        line-height: 26px;
    }
}