/* RANGE SLIDER */

.range-slider {
    height: 8px;
    border-radius: 2px;
    @extend .border-0;

    .noUi-pips {
        @extend .d-flex,
        .justify-content-between,
        .p-0,
        .mt-2;
        height: auto !important;

        .noUi-value {
            @extend .position-static,
            .fs-11,
            .text-gray1300;
            transform: none !important;
        }
    }

    .noUi-marker {
        @extend .d-none;
    }

    .noUi-base {
        .noUi-handle {
            top: -8px;
            right: -12px;
            cursor: grab;
            width: 24px;
            height: 24px;
            @extend .bg-green700,
            .shadow-none;
            border: 2px solid $white;
            border-radius: 4px;

            &:before,
            &:after {
                @extend .bg-white;
                top: 4px;
                height: 12px;
            }

            &:before {
                left: 6px;
            }

            &:after {
                left: 14px;
            }

            .noUi-touch-area {
                @extend .position-absolute,
                .top-50,
                .start-50,
                .translate-middle-y,
                .translate-middle-x,
                .bg-white;
                height: 12px;
                width: 1px;
            }
        }
    }

    .noUi-connect {
        @extend .bg-green400;
    }

    .noUi-connects {
        @extend .bg-gray400;
        box-shadow: 0px 0px 0px 2px $white inset;
    }
}