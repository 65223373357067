/* LOAN TYPES */

.loan-type {
    transition: 250ms;
    padding: 20px;
    border: 1px solid $gray1800;
    @extend .d-flex,
    .flex-column,
    .align-items-start,
    .flex-fill;

    @include media-breakpoint-up(md) {
        padding: 30px 20px;
    }

    &:hover,
    &:focus,
    &:active {
        @extend .bg-gray100;
    }

    .list-dash {
        @extend .fs-13;
        margin-bottom: 30px;
    }
}

.loan-type-icon {
    font-size: 38px;
    margin-bottom: 35px;
}

.loan-types-gallery {
    .swiper-wrapper {
        @extend .d-flex;
    }

    .swiper-slide {
        @extend .h-auto,
        .d-flex,
        .flex-column;
    }

    .loan-type-img {
        height: 38px;
        margin-bottom: 35px;
        max-width: 50px;
    }
}