/* why-exactly-box */

.h-446px {
    height: 446px !important;
}

.why-exactly-box {
    @extend .position-relative,
    .bg-pink500,
    .text-white,
    .d-md-flex,
    .justify-content-md-center,
    .flex-md-column,
    .align-items-md-start;
    padding: 100px 25px 50px 25px;
    font-size: 16px;

    @include media-breakpoint-up(md) {
        z-index: 10;
        padding: 60px 40px;
        margin-bottom: -85px;
        height: 500px;
    }

    @include media-breakpoint-up(lg) {
        padding: 124px 70px 123px 70px;
        margin-bottom: -61px;
        font-size: 20px;
    }

    &:before {
        @extend .position-absolute,
        .start-0,
        .top-0,
        .h-100,
        .bg-pink500;
        width: 200%;
        z-index: -1;

        @include media-breakpoint-up(md) {
            content: '';
        }
    }

    .list-dash-item {

        &:before {
            content: '';
            @extend .me-2,
            .flex-shrink-0,
            .bg-white;
            width: 25px;
            height: 2px;
            margin-top: 12px;

            @include media-breakpoint-up(lg) {
                width: 30px;
                height: 3px;
                margin-top: 13px;
            }
        }
    }

    .title {
        @include media-breakpoint-up(lg) {
            font-size: 36px;
        }
    }
}

.why-exactly-box-img-box {
    @extend .position-relative;

    &.no-purple-box {
        &:before {
            @include media-breakpoint-down(sm) {
                height: 0px !important;
            }
        }
    }

    &:before {
        @include media-breakpoint-up(md) {
            content: '';
            height: 260px;
        }

        @include media-breakpoint-down(md) {
            content: '';
            height: 95px;
            width: 55% !important;
            z-index: 20;
        }

        @extend .position-absolute,
        .start-0,
        .bottom-0,
        .w-100,
        .bg-pink600;
    }

    &--paterina {
        &:before {
            @include media-breakpoint-up(md) {
                margin-left: 50px;
                height: 415px;
            }

            @include media-breakpoint-up(lg) {
                margin-left: 50px;
                height: 439px;
            }

            @include media-breakpoint-up(xl) {
                margin-left: 95px;
                height: 439px;
            }

            @include media-breakpoint-up(xxl) {
                margin-left: 15px;
                height: 439px;
            }
        }
    }

    &--remontam {
        &:before {
            @include media-breakpoint-up(md) {
                margin-left: 0px;
                height: 415px;
            }

            @include media-breakpoint-up(lg) {
                margin-left: 35px;
                height: 439px;
            }

            @include media-breakpoint-up(xl) {
                margin-left: 35px;
                height: 439px;
            }

            @include media-breakpoint-up(xxl) {
                margin-left: 0px;
                height: 439px;
            }
        }
    }

    &--parkreditacija {
        &:before {
            @include media-breakpoint-up(md) {
                margin-left: 0px;
                height: 415px;
            }

            @include media-breakpoint-up(lg) {
                margin-left: 35px;
                height: 439px;
            }

            @include media-breakpoint-up(xl) {
                margin-left: 67px;
                height: 439px;
            }

            @include media-breakpoint-up(xxl) {
                margin-left: 0px;
                height: 439px;
            }
        }
    }

    &--auto {
        &:before {
            @include media-breakpoint-up(md) {
                margin-left: 0px;
                height: 361px;
            }

            @include media-breakpoint-up(lg) {
                margin-left: 55px;
                height: 385px;
            }

            @include media-breakpoint-up(xl) {
                margin-left: 104px;
                height: 385px;
            }

            @include media-breakpoint-up(xxl) {
                margin-left: 0px;
                height: 385px;
            }
        }
    }

    &--kreditu-apvienosana {
        &:before {
            @include media-breakpoint-up(md) {
                margin-left: 0px;
                height: 415px;
            }

            @include media-breakpoint-up(lg) {
                margin-left: 35px;
                height: 439px;
            }

            @include media-breakpoint-up(xl) {
                margin-left: 90px;
                height: 439px;
            }

            @include media-breakpoint-up(xxl) {
                margin-left: 0px;
                height: 439px;
            }
        }
    }

    &--nekustama-kredits {
        &:before {
            @include media-breakpoint-up(md) {
                margin-left: 0px;
                height: 415px;
            }

            @include media-breakpoint-up(lg) {
                margin-left: 35px;
                height: 439px;
            }

            @include media-breakpoint-up(xl) {
                margin-left: 90px;
                height: 439px;
            }

            @include media-breakpoint-up(xxl) {
                margin-left: 0px;
                height: 439px;
            }
        }
    }

    &--nekustama-ar-kilu-kredits {
        &:before {
            @include media-breakpoint-up(md) {
                margin-left: 0px;
                height: 415px;
            }

            @include media-breakpoint-up(lg) {
                margin-left: 35px;
                height: 439px;
            }

            @include media-breakpoint-up(xl) {
                margin-left: 90px;
                height: 439px;
            }

            @include media-breakpoint-up(xxl) {
                margin-left: 0px;
                height: 439px;
            }
        }
    }

    &--uznemumiem {
        &:before {
            @include media-breakpoint-up(md) {
                margin-left: 0px;
                height: 415px;
            }

            @include media-breakpoint-up(lg) {
                margin-left: 35px;
                height: 439px;
            }

            @include media-breakpoint-up(xl) {
                margin-left: 90px;
                height: 439px;
            }

            @include media-breakpoint-up(xxl) {
                margin-left: 0px;
                height: 439px;
            }
        }
    }
    &--loan {
        &:before {
            @include media-breakpoint-up(md) {
                margin-left: 0px;
                height: 415px;
            }

            @include media-breakpoint-up(lg) {
                margin-left: 35px;
                height: 439px;
            }

            @include media-breakpoint-up(xl) {
                margin-left: 50px;
                height: 439px;
            }

            @include media-breakpoint-up(xxl) {
                margin-left: 0px;
                height: 439px;
            }
        }
    }
    &--creditline {
        &:before {
            @include media-breakpoint-up(md) {
                margin-left: 0px;
                height: 415px;
            }

            @include media-breakpoint-up(lg) {
                margin-left: 35px;
                height: 439px;
            }

            @include media-breakpoint-up(xl) {
                margin-left: 90px;
                height: 439px;
            }

            @include media-breakpoint-up(xxl) {
                margin-left: 0px;
                height: 439px;
            }
        }
    }
    &--fast-loan {
        &:before {
            @include media-breakpoint-up(md) {
                margin-left: 0px;
                height: 415px;
            }

            @include media-breakpoint-up(lg) {
                margin-left: 35px;
                height: 439px;
            }

            @include media-breakpoint-up(xl) {
                margin-left: 30px;
                height: 439px;
            }

            @include media-breakpoint-up(xxl) {
                margin-left: 0px;
                height: 439px;
            }
        }
    }
}

.why-exactly-box-img-mobile {
    max-width: 60%;
    width: 100%;
    margin-bottom: -70px;
    @extend .position-relative;
    z-index: 20;
    &.img-mobile-diff-height {
        margin-bottom: -49px;
    }
    &--loan {
        max-width: 52%;
    }
}

.why-exactly-box-img {
    &--consumer {
        z-index: 21;
        position: relative;
        @include media-breakpoint-up(md) {
            width: 100% !important;
        }
        @include media-breakpoint-up(lg) {
            width: 466px !important;
            transform: translate(-29px, 0px) !important;
        }
        @include media-breakpoint-up(xl) {
            width: 466px !important;
            transform: translate(47px, 0px) !important;
        }
        @include media-breakpoint-up(xxl) {
            width: 466px !important;
            transform: translate(-49px, 0px) !important;
        }
    }
    &--renovation {
        z-index: 21;
        position: relative;
        @include media-breakpoint-up(md) {
            width: 100% !important;
        }
        @include media-breakpoint-up(lg) {
            width: 401px !important;
            transform: translate(54px, 0px) !important;
        }
        @include media-breakpoint-up(xl) {
            width: 434px !important;
            transform: translate(47px, 0px) !important;
        }
        @include media-breakpoint-up(xxl) {
            width: 434px !important;
            transform: translate(4px, 0px) !important;
        }
    }
    &--merge {
        z-index: 21;
        position: relative;
        @include media-breakpoint-up(md) {
            width: 100% !important;
        }
        @include media-breakpoint-up(lg) {
            width: 401px !important;
            transform: translate(-6px, 0px) !important;
        }
        @include media-breakpoint-up(xl) {
            width: 434px !important;
            transform: translate(47px, 0px) !important;
        }
        @include media-breakpoint-up(xxl) {
            width: 434px !important;
            transform: translate(-47px, 0px) !important;
        }
    }
    &--car {
        z-index: 21;
        position: relative;
        @include media-breakpoint-up(md) {
            width: 100% !important;
        }
        @include media-breakpoint-up(lg) {
            width: 401px !important;
            transform: translate(25px, 0px) !important;
        }
        @include media-breakpoint-up(xl) {
            width: 434px !important;
            transform: translate(72px, 0px) !important;
        }
        @include media-breakpoint-up(xxl) {
            width: 434px !important;
            transform: translate(-28px, 0px) !important;
        }
    }
    &--collateral {
        z-index: 21;
        position: relative;
        @include media-breakpoint-up(md) {
            width: 100% !important;
        }
        @include media-breakpoint-up(lg) {
            width: 371px !important;
            transform: translate(38px, 0px) !important;
        }
        @include media-breakpoint-up(xl) {
            width: 398px !important;
            transform: translate(84px, 0px) !important;
        }
        @include media-breakpoint-up(xxl) {
            width: 398px !important;
            transform: translate(-8px, 0px) !important;
        }
    }
    &--realestate {
        z-index: 21;
        position: relative;
        @include media-breakpoint-up(md) {
            width: 100% !important;
        }
        @include media-breakpoint-up(lg) {
            width: 371px !important;
            transform: translate(38px, 0px) !important;
        }
        @include media-breakpoint-up(xl) {
            width: 398px !important;
            transform: translate(84px, 0px) !important;
        }
        @include media-breakpoint-up(xxl) {
            width: 398px !important;
            transform: translate(-8px, 0px) !important;
        }
    }
    &--refinancing {
        z-index: 21;
        position: relative;
        @include media-breakpoint-up(md) {
            width: 100% !important;
        }
        @include media-breakpoint-up(lg) {
            width: 403px !important;
            transform: translate(-6px, 0px) !important;
        }
        @include media-breakpoint-up(xl) {
            width: 450px !important;
            transform: translate(21px, 0px) !important;
        }
        @include media-breakpoint-up(xxl) {
            width: 450px !important;
            transform: translate(-69px, 0px) !important;
        }
    }
    &--company {
        z-index: 21;
        position: relative;
        @include media-breakpoint-up(md) {
            width: 100% !important;
        }
        @include media-breakpoint-up(lg) {
            width: 373px !important;
            transform: translate(-6px, 0px) !important;
        }
        @include media-breakpoint-up(xl) {
            width: 384px !important;
            transform: translate(47px, 0px) !important;
        }
        @include media-breakpoint-up(xxl) {
            width: 384px !important;
            transform: translate(-47px, 0px) !important;
        }
    }
    &--loan {
        z-index: 21;
        position: relative;
        @include media-breakpoint-up(md) {
            width: calc(100% - 30px) !important;
            transform: translate(15px, 0px) !important;
        }
        @include media-breakpoint-up(lg) {
            width: 333px !important;
            transform: translate(44px, 0px) !important;
        }
        @include media-breakpoint-up(xl) {
            width: 333px !important;
            transform: translate(95px, 0px) !important;
        }
        @include media-breakpoint-up(xxl) {
            width: 333px !important;
            transform: translate(14px, 0px) !important;
        }
    }
    &--creditline {
        z-index: 21;
        position: relative;
        @include media-breakpoint-up(md) {
            width: 100% !important;
        }
        @include media-breakpoint-up(lg) {
            width: 433px !important;
            transform: translate(-6px, 0px) !important;
        }
        @include media-breakpoint-up(xl) {
            width: 460px !important;
            transform: translate(47px, 0px) !important;
        }
        @include media-breakpoint-up(xxl) {
            width: 460px !important;
            transform: translate(-47px, 0px) !important;
        }
    }
    &--fast-loan {
        z-index: 21;
        position: relative;
        @include media-breakpoint-up(md) {
            width: 100% !important;
            transform: translate(-27px, 0px) !important;
        }
        @include media-breakpoint-up(lg) {
            width: 373px !important;
            transform: translate(-6px, 0px) !important;
        }
        @include media-breakpoint-up(xl) {
            width: 400px !important;
            transform: translate(47px, 0px) !important;
        }
        @include media-breakpoint-up(xxl) {
            width: 384px !important;
            transform: translate(-47px, 0px) !important;
        }
    }
}
