/* HERO CONTACTS */

.hero-contacts {
    @extend .position-relative,
    .bg-pink500,
    .pb-md-0,
    .text-white;
    z-index: 10;
    padding: 24px 0 25% 0;
    margin-bottom: 50px;

    @media (min-width: 770px) {
        padding: 24px 0 50% 0;
    }

    @include media-breakpoint-down(md) {
        font-size: 15px;
    }

    &:before {
        @extend .position-absolute,
        .end-0,
        .bottom-0,
        .bg-white;

        @include media-breakpoint-up(md) {
            content: '';
            z-index: -1;
        }
    }

    &:before {
        @include media-breakpoint-up(md) {
            width: 35%;
            height: 147px;
        }

        @include media-breakpoint-up(lg) {
            height: 170px;
        }

        @include media-breakpoint-up(xl) {
            width: 45%;
            height: 244px;
        }
    }
}

.hero-contacts__img-desktop {
    @extend .w-100;
    max-width: 684px;
    margin-left: -60px;
}

.hero-contacts__img-mobile {
    @extend .w-100,
    .position-absolute,
    .end-0;

    max-width: 260px;
    max-height: 220px;
    bottom: -40px;
}

.hero-contacts-content-box {
    @extend .pt-md-4;

    @include media-breakpoint-up(md) {
        padding-bottom: 120px;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 40px;
    }
}