/* HERO BLOG */

.hero-blog {
    @extend .bg-green200,
    .text-white;
    padding-top: 34px;

    @include media-breakpoint-up(md) {
        padding-top: 55px;
    }
}

.hero-blog-content-box {
    padding-bottom: 25px;

    @include media-breakpoint-up(md) {
        padding-bottom: 65px;
    }
}

.hero-blog-form {
    .icon {
        @extend .position-absolute,
        .top-50,
        .translate-middle-y,
        .text-primary;
        left: 12px;
        font-size: 20px;
    }

    .form-control {
        padding-left: 50px;
    }
}

.hero-blog-nav-holder {
    @extend .mt-md-0,
    .position-relative;
    margin-top: 35px;
    z-index: 10;

    &:after {
        @extend .top-0,
        .position-absolute,
        .h-100;
        width: 51px;
        right: -$global-space;
        background: linear-gradient(270deg, #0F6718 31.52%, rgba(15, 103, 24, 0) 100%);

        @include media-breakpoint-down(md) {
            content: '';
        }
    }

    &:before {
        @extend .position-absolute,
        .h-100,
        .bg-green600,
        .bottom-0;

        @include media-breakpoint-up(md) {
            content: '';
            z-index: -1;
            width: 200%;
            left: 100%;
        }
    }
}

.hero-blog-nav {
    @extend .bg-green600,
    .d-flex,
    .flex-nowrap,
    .overflow-auto;

    padding: 17.5px 20px;

    @include media-breakpoint-down(md) {
        padding: 6.5px 25px;
        margin-left: -$global-space;
        margin-right: -$global-space;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
}

.hero-blog-nav::-webkit-scrollbar {
    display: none;
}

.hero-blog-nav-item {
    @extend .text-white,
    .font-secondary-bold,
    .me-1,
    .flex-shrink-0;
    padding: 8px 14px;
    border-radius: 6px;
    font-size: 14px;

    @include media-breakpoint-up(md) {
        font-size: 16px;
    }

    &-active,
    &:hover,
    &:focus,
    &:active {
        @extend .text-green600,
        .bg-white;
    }
}