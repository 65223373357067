/* Fonts icons */

//Icomoon
@font-face {
    font-family: 'icomoon';
    src: url('#{$font-path}icomoon/icomoon.eot?raxetc');
    src: url('#{$font-path}icomoon/icomoon.eot?raxetc#iefix') format('embedded-opentype'),
    url('#{$font-path}icomoon/icomoon.ttf?raxetc') format('truetype'),
    url('#{$font-path}icomoon/icomoon.woff?raxetc') format('woff'),
    url('#{$font-path}icomoon/icomoon.svg?raxetc#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.icon-minus:before {
    content: "\e90d";
}

.icon-plus:before {
    content: "\e90e";
}

.icon-refinancing:before {
    content: "\e912";
}

.icon-company:before {
    content: "\e913";
}

.icon-money-bag:before {
    content: "\e914";
}

.icon-consumer:before {
    content: "\e915";
}

.icon-renovation:before {
    content: "\e916";
}

.icon-merge:before {
    content: "\e917";
}

.icon-collateral:before {
    content: "\e918";
}

.icon-realestate:before {
    content: "\e919";
}

.icon-car:before {
    content: "\e91a";
}

.icon-chat:before {
    content: "\e91b";
}

.icon-coconut-drink:before {
    content: "\e91c";
}

.icon-search:before {
    content: "\e90f";
}

.icon-arrow-right:before {
    content: "\e910";
}

.icon-arrow-left:before {
    content: "\e911";
}

.icon-quote:before {
    content: "\e90c";
}

.icon-doc:before {
    content: "\e90a";
}

.icon-monitor:before {
    content: "\e90b";
}

.icon-check:before {
    content: "\e900";
}

.icon-chevron-down:before {
    content: "\e901";
}

.icon-chevron-left:before {
    content: "\e902";
}

.icon-chevron-right:before {
    content: "\e903";
}

.icon-chevron-up:before {
    content: "\e904";
}

.icon-flag:before {
    content: "\e905";
}

.icon-info:before {
    content: "\e906";
}

.icon-mail:before {
    content: "\e907";
}

.icon-phone:before {
    content: "\e908";
}

.icon-shield:before {
    content: "\e909";
}