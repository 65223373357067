/* Fonts */

//Inter
@font-face {
    font-family: 'Inter-Regular';
    src: url('#{$font-path}Inter/Inter-Regular.woff2') format('woff2'),
    url('#{$font-path}Inter/Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter-Medium';
    src: url('#{$font-path}Inter/Inter-Medium.woff2') format('woff2'),
    url('#{$font-path}Inter/Inter-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter-SemiBold';
    src: url('#{$font-path}Inter/Inter-SemiBold.woff2') format('woff2'),
    url('#{$font-path}Inter/Inter-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter-Bold';
    src: url('#{$font-path}Inter/Inter-Bold.woff2') format('woff2'),
    url('#{$font-path}Inter/Inter-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}





//Nunito
@font-face {
    font-family: 'Nunito-SemiBold';
    src: url('#{$font-path}Nunito/Nunito-SemiBold.woff2') format('woff2'),
    url('#{$font-path}Nunito/Nunito-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito-Bold';
    src: url('#{$font-path}Nunito/Nunito-Bold.woff2') format('woff2'),
    url('#{$font-path}Nunito/Nunito-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito-Black';
    src: url('#{$font-path}Nunito/Nunito-Black.woff2') format('woff2'),
    url('#{$font-path}Nunito/Nunito-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Nunito-ExtraBold';
    src: url('#{$font-path}Nunito/Nunito-ExtraBold.woff2') format('woff2'),
    url('#{$font-path}Nunito/Nunito-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}