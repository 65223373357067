/* CTA BOX */

.cta-box {
    @extend .position-relative,
    .bg-green200;
    z-index: 10;

    @include media-breakpoint-down(md) {
        padding: 40px 25px;
        margin-left: -$global-space;
        margin-right: -$global-space;
    }

    @include media-breakpoint-up(md) {
        padding: 65px 50px;
    }

    @include media-breakpoint-up(md) {
        padding: 65px 75px;
    }

    &:before {
        @extend .position-absolute,
        .h-100,
        .bg-green200,
        .bottom-0;

        @include media-breakpoint-up(md) {
            content: '';
            z-index: -1;
            width: 200%;
            left: 100%;
        }
    }
}

.cta-items {
    @extend .d-flex,
    .flex-column,
    .flex-lg-row,
    .flex-wrap;
}

.cta-item {
    @extend .d-flex,
    .text-white,
    .font-secondary-bold,
    .align-items-center;
    letter-spacing: -.2px;

    &:hover,
    &:focus,
    &:active {
        @extend .text-green900;
        .icon-email {
            background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHdpZHRoPSI2NSIgaGVpZ2h0PSI2NSI+PHJlY3QgaWQ9ImJhY2tncm91bmRyZWN0IiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB4PSIwIiB5PSIwIiBmaWxsPSJub25lIiBzdHJva2U9Im5vbmUiLz48ZyBjbGFzcz0iY3VycmVudExheWVyIiBzdHlsZT0iIj48dGl0bGU+TGF5ZXIgMTwvdGl0bGU+PHBhdGggZD0iTTM4LjU5NCAxMi4xODhIMTQuMjE5YTIuMDMyIDIuMDMyIDAgMDAwIDQuMDYyaDI0LjM3NWEyLjAzMiAyLjAzMiAwIDAwMC00LjA2M3ptLTguMTI1IDguMTI1aC0xNi4yNWEyLjAzMiAyLjAzMiAwIDAwMCA0LjA2MmgxNi4yNWEyLjAzMiAyLjAzMiAwIDAwMC00LjA2M3oiIGZpbGw9IiMxMTQwMTUiIGlkPSJzdmdfMSIgY2xhc3M9IiIgZmlsbC1vcGFjaXR5PSIxIi8+PHBhdGggZD0iTTQ0LjY4OCAwSDguMTI0QzMuNjQ1IDAgMCAzLjY0NCAwIDguMTI1VjQ4Ljc1YTIuMDMgMi4wMyAwIDAwMy4zMzEgMS41NmwxMS42MjMtOS42ODVoMjkuNzMzYzQuNDgxIDAgOC4xMjYtMy42NDQgOC4xMjYtOC4xMjVWOC4xMjVjMC00LjQ4LTMuNjQ1LTguMTI1LTguMTI2LTguMTI1em00LjA2MiAzMi41YTQuMDY3IDQuMDY3IDAgMDEtNC4wNjMgNC4wNjNIMTQuMjJhMi4wMyAyLjAzIDAgMDAtMS4zLjQ3bC04Ljg1NyA3LjM4MlY4LjEyNWE0LjA2NyA0LjA2NyAwIDAxNC4wNjMtNC4wNjNoMzYuNTYzYTQuMDY3IDQuMDY3IDAgMDE0LjA2MiA0LjA2M1YzMi41eiIgZmlsbD0iIzExNDAxNSIgaWQ9InN2Z18yIiBjbGFzcz0iIiBmaWxsLW9wYWNpdHk9IjEiLz48cGF0aCBkPSJNNTYuODc1IDE2LjI1YTIuMDMyIDIuMDMyIDAgMDAwIDQuMDYzIDQuMDY3IDQuMDY3IDAgMDE0LjA2MyA0LjA2MlY1OC43NGwtNi44NTgtNS40ODVhMi4wNDYgMi4wNDYgMCAwMC0xLjI2Ny0uNDQzSDI0LjM3NWE0LjA2NyA0LjA2NyAwIDAxLTQuMDYzLTQuMDYydi0yLjAzMWEyLjAzMiAyLjAzMiAwIDAwLTQuMDYyIDB2Mi4wMzFjMCA0LjQ4IDMuNjQ0IDguMTI1IDguMTI1IDguMTI1aDI3LjcyMmw5LjYgNy42ODJhMi4wNTEgMi4wNTEgMCAwMDIuMTUzLjI0NEEyLjAzOCAyLjAzOCAwIDAwNjUgNjIuOTY5VjI0LjM3NWMwLTQuNDgtMy42NDQtOC4xMjUtOC4xMjUtOC4xMjV6IiBmaWxsPSIjMTE0MDE1IiBpZD0ic3ZnXzMiIGNsYXNzPSJzZWxlY3RlZCIgZmlsbC1vcGFjaXR5PSIxIi8+PC9nPjwvc3ZnPg==') no-repeat center !important;
        }
        .icon-download {
            background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHdpZHRoPSI2NSIgaGVpZ2h0PSI2NSIgc3R5bGU9IiI+PHJlY3QgaWQ9ImJhY2tncm91bmRyZWN0IiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB4PSIwIiB5PSIwIiBmaWxsPSJub25lIiBzdHJva2U9Im5vbmUiLz48ZyBjbGFzcz0iY3VycmVudExheWVyIiBzdHlsZT0iIj48dGl0bGU+TGF5ZXIgMTwvdGl0bGU+PHBhdGggZD0iTTYxLjQ1NSAzLjU0NUExMi4wMjQgMTIuMDI0IDAgMDA1Mi44OTcgMEgxMi4xMDNDNS40MjkgMCAwIDUuNDMgMCAxMi4xMDN2NDAuNzk0QzAgNTkuNTcxIDUuNDMgNjUgMTIuMTAzIDY1aDQwLjc5NEM1OS41NzEgNjUgNjUgNTkuNTcgNjUgNTIuODk3VjEyLjEwM2MwLTMuMjMzLTEuMjU5LTYuMjcyLTMuNTQ1LTguNTU4em0tLjI2NCA0OS4zNTJjMCA0LjU3NC0zLjcyIDguMjk0LTguMjk0IDguMjk0SDEyLjEwM2MtNC41NzMgMC04LjI5NC0zLjcyLTguMjk0LTguMjk0VjEyLjEwM2MwLTQuNTczIDMuNzItOC4yOTQgOC4yOTQtOC4yOTRoNDAuNzk0YTguMjQgOC4yNCAwIDAxNS44NjUgMi40MjkgOC4yNCA4LjI0IDAgMDEyLjQzIDUuODY1djQwLjc5NHoiIGZpbGw9IiMxMTQwMTUiIGlkPSJzdmdfMSIgY2xhc3M9IiIgZmlsbC1vcGFjaXR5PSIxIi8+PHBhdGggZD0iTTQzLjk0MiAyNy42MTJhMS45MDQgMS45MDQgMCAwMC0yLjY5MyAwbC02Ljg0NSA2Ljg0NVYxNC4xNDNhMS45MDQgMS45MDQgMCAwMC0zLjgwOSAwdjIwLjMxNWwtNi44NDUtNi44NDZhMS45MDQgMS45MDQgMCAxMC0yLjY5MyAyLjY5M2wxMC4wOTYgMTAuMDk2YTEuOTA0IDEuOTA0IDAgMDAyLjY5MyAwbDEwLjA5Ni0xMC4wOTZhMS45MDQgMS45MDQgMCAwMDAtMi42OTN6bTYuOTE1IDIxLjM0MUgxNC4xNDNhMS45MDQgMS45MDQgMCAwMDAgMy44MDloMzYuNzE0YTEuOTA0IDEuOTA0IDAgMDAwLTMuODA5eiIgZmlsbD0iIzExNDAxNSIgaWQ9InN2Z18yIiBjbGFzcz0ic2VsZWN0ZWQiIGZpbGwtb3BhY2l0eT0iMSIvPjwvZz48L3N2Zz4=') no-repeat center !important;
        }
    }

    &+.cta-item {
        @include media-breakpoint-up(lg) {
            margin-left: 65px;
        }

        @include media-breakpoint-down(lg) {
            margin-top: 30px;
        }
    }

    .icon-email {
        background: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNjUgNjUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTM4LjU5NCAxMi4xODhIMTQuMjE5YTIuMDMyIDIuMDMyIDAgMDAwIDQuMDYyaDI0LjM3NWEyLjAzMiAyLjAzMiAwIDAwMC00LjA2M3ptLTguMTI1IDguMTI1aC0xNi4yNWEyLjAzMiAyLjAzMiAwIDAwMCA0LjA2MmgxNi4yNWEyLjAzMiAyLjAzMiAwIDAwMC00LjA2M3oiIGZpbGw9IiNmZmYiLz48cGF0aCBkPSJNNDQuNjg4IDBIOC4xMjRDMy42NDUgMCAwIDMuNjQ0IDAgOC4xMjVWNDguNzVhMi4wMyAyLjAzIDAgMDAzLjMzMSAxLjU2bDExLjYyMy05LjY4NWgyOS43MzNjNC40ODEgMCA4LjEyNi0zLjY0NCA4LjEyNi04LjEyNVY4LjEyNWMwLTQuNDgtMy42NDUtOC4xMjUtOC4xMjYtOC4xMjV6bTQuMDYyIDMyLjVhNC4wNjcgNC4wNjcgMCAwMS00LjA2MyA0LjA2M0gxNC4yMmEyLjAzIDIuMDMgMCAwMC0xLjMuNDdsLTguODU3IDcuMzgyVjguMTI1YTQuMDY3IDQuMDY3IDAgMDE0LjA2My00LjA2M2gzNi41NjNhNC4wNjcgNC4wNjcgMCAwMTQuMDYyIDQuMDYzVjMyLjV6IiBmaWxsPSIjZmZmIi8+PHBhdGggZD0iTTU2Ljg3NSAxNi4yNWEyLjAzMiAyLjAzMiAwIDAwMCA0LjA2MyA0LjA2NyA0LjA2NyAwIDAxNC4wNjMgNC4wNjJWNTguNzRsLTYuODU4LTUuNDg1YTIuMDQ2IDIuMDQ2IDAgMDAtMS4yNjctLjQ0M0gyNC4zNzVhNC4wNjcgNC4wNjcgMCAwMS00LjA2My00LjA2MnYtMi4wMzFhMi4wMzIgMi4wMzIgMCAwMC00LjA2MiAwdjIuMDMxYzAgNC40OCAzLjY0NCA4LjEyNSA4LjEyNSA4LjEyNWgyNy43MjJsOS42IDcuNjgyYTIuMDUxIDIuMDUxIDAgMDAyLjE1My4yNDRBMi4wMzggMi4wMzggMCAwMDY1IDYyLjk2OVYyNC4zNzVjMC00LjQ4LTMuNjQ0LTguMTI1LTguMTI1LTguMTI1eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==') no-repeat center;
    }

    .icon-download {
        background: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNjUgNjUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTYxLjQ1NSAzLjU0NUExMi4wMjQgMTIuMDI0IDAgMDA1Mi44OTcgMEgxMi4xMDNDNS40MjkgMCAwIDUuNDMgMCAxMi4xMDN2NDAuNzk0QzAgNTkuNTcxIDUuNDMgNjUgMTIuMTAzIDY1aDQwLjc5NEM1OS41NzEgNjUgNjUgNTkuNTcgNjUgNTIuODk3VjEyLjEwM2MwLTMuMjMzLTEuMjU5LTYuMjcyLTMuNTQ1LTguNTU4em0tLjI2NCA0OS4zNTJjMCA0LjU3NC0zLjcyIDguMjk0LTguMjk0IDguMjk0SDEyLjEwM2MtNC41NzMgMC04LjI5NC0zLjcyLTguMjk0LTguMjk0VjEyLjEwM2MwLTQuNTczIDMuNzItOC4yOTQgOC4yOTQtOC4yOTRoNDAuNzk0YTguMjQgOC4yNCAwIDAxNS44NjUgMi40MjkgOC4yNCA4LjI0IDAgMDEyLjQzIDUuODY1djQwLjc5NHoiIGZpbGw9IiNmZmYiLz48cGF0aCBkPSJNNDMuOTQyIDI3LjYxMmExLjkwNCAxLjkwNCAwIDAwLTIuNjkzIDBsLTYuODQ1IDYuODQ1VjE0LjE0M2ExLjkwNCAxLjkwNCAwIDAwLTMuODA5IDB2MjAuMzE1bC02Ljg0NS02Ljg0NmExLjkwNCAxLjkwNCAwIDEwLTIuNjkzIDIuNjkzbDEwLjA5NiAxMC4wOTZhMS45MDQgMS45MDQgMCAwMDIuNjkzIDBsMTAuMDk2LTEwLjA5NmExLjkwNCAxLjkwNCAwIDAwMC0yLjY5M3ptNi45MTUgMjEuMzQxSDE0LjE0M2ExLjkwNCAxLjkwNCAwIDAwMCAzLjgwOWgzNi43MTRhMS45MDQgMS45MDQgMCAwMDAtMy44MDl6IiBmaWxsPSIjZmZmIi8+PC9zdmc+') no-repeat center;
    }

    .icon {
        @extend .flex-shrink-0;
        margin-right: 28px;
        width: 51px; 
        height: 51px;

        @include media-breakpoint-up(md) {
            width: 65px; 
            height: 65px;
        }
    }
}