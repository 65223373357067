/* cash-loan-form */

.cash-loan-form {
    display: grid;
    @extend .align-items-start;
    grid-gap: 20px;

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(1, auto 1fr);
        grid-gap: 25px;
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(1, 270px 1fr);
        grid-gap: 25px;
    }
}