/* INFO BOX */

.info-box {
    @include media-breakpoint-down(md) {
        padding: 40px 25px;
        margin-left: -$global-space;
        margin-right: -$global-space;
    }

    @include media-breakpoint-up(md) {
        padding: 72px 15px;
    }
}

.info-box-title {
    font-size: 28px;

    @include media-breakpoint-up(md) {
        font-size: 42px;
    }
}