.contact-icon {
    @extend .flex-shrink-0;
    font-size: 36px;
    margin-right: 20px;
}

.contact-phone {
    @extend .text-primary,
    .font-secondary-extrabold,
    .d-inline-flex,
    .align-items-center;

    &:hover,
    &:focus,
    &:active {
        @extend .text-orange500;
    }

    margin-bottom: 10px;
    font-size: 39px;
    line-height: 0.74;
    letter-spacing: -0.21px;

    @include media-breakpoint-up(lg) {
        font-size: 54px;
        letter-spacing: -0.29px;
        margin-bottom: 15px;
    }

    &-img {
        margin-right: 14px;
        width: 26px;
        height: 27px;

        @include media-breakpoint-up(md) {
            width: 36px;
            height: 38px;
            margin-right: 20px;
        }
        
    }
}

.contact-location-pin-img {
    padding-right: 15px;
    padding-top: 0;

    @include media-breakpoint-up(md) {
        padding-top: 2px;
    }
}

.contact-work-hours {
    @extend .fs-12;
    color: #5f6677;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
        margin-bottom: 45px;
    }
}

.contact-link-box {
    @extend .font-secondary-semibold;
    font-size: 12px;

    @include media-breakpoint-up(md) {
        font-size: 16px;
    }

    &+.contact-link-box {
        margin-top: 12px;

        @include media-breakpoint-up(md) {
            margin-top: 22px;
        }
    }
}

.contact-link {
    @extend .text-orange500,
    .font-secondary-bold;
    font-size: 22px;

    &:hover,
    &:focus,
    &:active {
        @extend .text-primary;
    }
}

.contact-box {
    @extend .text-gray1500;
    font-size: 13px;
    letter-spacing: -0.2px;

    @include media-breakpoint-up(md) {
        padding-top: 55px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 16px;
        padding-top: 70px;
    }
}

.contact-address {
    @extend .m-0;
    display: grid;
    grid-gap: 5px;
    word-break: break-word;
}

.contact-address-item {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(1, 125px 1fr);

    @include media-breakpoint-up(md) {
        grid-gap: 60px;
    }
}

.contact-address-item-val {
    @extend .text-primary,
    .font-base-semibold;
}
.contact-form-success {
    display: flex;
    justify-content: center;
}

.contact-form-success-icon {
    font-size: 3rem;
}

#contacts-form {
    small {
        color: red;
        font-size: 0.55rem !important;
    }
}