/* VARIABLES */

/*************************
	Base variables
*************************/
$base-animation: 250ms;

/*************************
	Fonts variables
*************************/
$font-base-regular: "Inter-Regular";

.font-base-regular {
  font-family: $font-base-regular;
}

$font-base-medium: "Inter-Medium";

.font-base-medium {
  font-family: $font-base-medium;
}

$font-base-semibold: "Inter-SemiBold";

.font-base-semibold {
  font-family: $font-base-semibold;
}

$font-base-bold: "Inter-Bold";

.font-base-bold {
  font-family: $font-base-bold;
}

$font-secondary-semibold: "Nunito-SemiBold";

.font-secondary-semibold {
  font-family: $font-secondary-semibold;
}

$font-secondary-bold: "Nunito-Bold";

.font-secondary-bold {
  font-family: $font-secondary-bold;
}

$font-secondary-black: "Nunito-Black";

.font-secondary-black {
  font-family: $font-secondary-black;
}

$font-secondary-extrabold: "Nunito-ExtraBold";

.font-secondary-extrabold {
  font-family: $font-secondary-extrabold;
}

.font-icons {
  &:before {
    font-family: "icomoon";
  }
}

/*************************
	Font path variable
*************************/
$font-path: "../fonts/";

/*************************
	Body variables
*************************/
$body-font-size-desktop: 18px;
$body-font-size-mobile: 16px;
$body-line-height: 1.4;
$body-color: $primary;

/*************************
	Heading variables
*************************/
$heading-font: $font-secondary-bold;
