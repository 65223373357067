/* PAGINATION */

.page-item {
    &:not(:last-of-type) {
        margin-right: 4px;

        @include media-breakpoint-up(md) {
            margin-right: 6px;
        }
    }

    &.active {
        .page-link {
            @extend .bg-orange500,
            .text-white;
            border-color: $orange500;
        }
    }

    &-divider {
        @extend .mx-2,
        .font-base-bold;
        font-size: 22px;
    }
}

.page-link {
    @extend .d-flex,
    .align-items-center,
    .justify-content-center,
    .text-center,
    .font-base-bold,
    .text-primary,
    .lh-1;

    &:not(.page-link-button) {
        @extend .rounded-circle,
        .p-0;
        width: 32px;

        @include media-breakpoint-up(md) {
            width: 40px;
        }
    }

    height: 32px;
    font-size: 14px;
    border-color: #d4d4d6;

    @include media-breakpoint-up(md) {
        height: 40px;
        font-size: 15px;
    }

    &-button {
        border-radius: 6px;
        padding: 0 12px;

        @include media-breakpoint-up(md) {
            padding: 0 15px;
        }
    }
}