/* SECTION */

.section {
    padding: 40px 0;
    @extend .position-relative;

    @include media-breakpoint-up(md) {
        padding: 90px 0;
    }

    &-before-hero {
        @include media-breakpoint-up(md) {
            padding-top: 165px;
        }
    }

    &-process {
        @include media-breakpoint-up(md) {
            padding-bottom: 90px;
        }
    }

    &-loan-types {

        @include media-breakpoint-up(md) {
            padding: 60px 0 30px 0;
        }

        @include media-breakpoint-up(lg) {
            padding: 60px 0 0px 0;
        }

        &:before {
            @extend .position-absolute,
            .top-0,
            .start-0,
            .bg-gray200;
            z-index: -1;

            @media (min-width: 1350px) {
                width: 7%;
                content: '';
                height: 160px;
            }

            @media (min-width: 1450px) {
                width: 9%;
            }

            @media (min-width: 1550px) {
                width: 11%;
            }

            @media (min-width: 1650px) {
                width: 13%;
            }

            @media (min-width: 1800px) {
                width: 16%;
            }

        }
    }

    &-advantages-of-choice {
        @extend .overflow-hidden;
        padding: 0;
        z-index: 20;

        @include media-breakpoint-up(md) {
            padding-bottom: 20px;
        }

        &:before,
        &:after {
            @extend .position-absolute,
            .bottom-0;
            z-index: -1;

            @include media-breakpoint-up(md) {
                content: '';
                height: 100%;
            }

            @include media-breakpoint-up(lg) {
                height: 90%;
                margin-top: 50px;
            }
        }

        &:before {
            width: 54%;
            left: 0;
            @extend .bg-yellow500;
        }

        &:after {
            width: 46%;
            right: 0;
            @extend .bg-white;
        }

        .img-floated-mobile {
            margin-right: -$global-space;
        }

        .floated-img-holder {
            @extend .position-relative,
            .flex-fill,
            .d-flex,
            .flex-column;
            z-index: 30;

            &:after {
                @extend .d-block,
                .w-100,
                .bg-white,
                .mt-auto;
                height: 30px;
                margin-bottom: -20px;
                z-index: 1;

                @include media-breakpoint-up(md) {
                    content: '';
                }
            }

            .img-floated {
                @extend .position-absolute,
                .start-0,
                .w-100;
                bottom: 10px;

                @include media-breakpoint-up(md) {
                    max-width: 265px;
                    transform: translateX(-10px);
                }

                @include media-breakpoint-up(lg) {
                    max-width: 330px;
                    transform: translateX(-45px);
                }

                @include media-breakpoint-up(xl) {
                    transform: translateX(-65px);
                    max-width: 435px;
                }
            }

            .img-mask {
                @include media-breakpoint-up(lg) {
                    transform: translateY(100px);
                }

                @include media-breakpoint-up(xl) {
                    transform: translateY(60px);
                }

                @include media-breakpoint-up(xxl) {
                    transform: translateY(60px);
                }
            }
        }
    }

    &-cash-loan {
        @extend .py-0,
        .overflow-hidden;
        z-index: 10;
    }

    &-prefooter {
        @extend .section-loan-types;

        .quotes-holder {
            margin-bottom: 45px;

            @include media-breakpoint-up(md) {
                margin-bottom: 90px;
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: 85px;
            }

            @include media-breakpoint-up(xl) {
                margin-bottom: 105px;
            }

            @include media-breakpoint-up(xxl) {
                margin-bottom: 160px;
            }
        }
    }

    &-about-stats {
        @extend .bg-white,
        .py-0;
        z-index: 10;

        &:before {
            @extend .position-absolute,
            .w-100,
            .start-0,
            .bottom-0,
            .bg-gray200;

            @include media-breakpoint-up(md) {
                content: '';
                z-index: -1;
                height: 45%;
            }
        }
    }

    &-testimonial {
        z-index: 9;
        @extend .pb-0;

        &:after {
            @extend .w-100,
            .position-absolute,
            .w-100,
            .start-0,
            .bottom-0,
            .bg-white;
            content: '';
            height: 30px;
            z-index: -1;

            @include media-breakpoint-up(lg) {
                height: 87px;
            }
        }
    }

    &-we-promise {
        padding-bottom: 0;

        @include media-breakpoint-up(md) {
            padding: 100px 0 130px 0;
        }

        @include media-breakpoint-up(lg) {
            padding: 100px 0 125px 0;
        }
    }

    &-info-about-us {
        @include media-breakpoint-up(md) {
            padding: 54px 0 100px 0;
        }
    }

    &-map {
        z-index: 10;

        &:after {
            content: '';
            @extend .position-absolute,
            .bg-white,
            .start-0,
            .bottom-0,
            .w-100;
            z-index: -1;
            height: 134px;

            @include media-breakpoint-up(md) {
                height: 20px;
            }

            @include media-breakpoint-up(lg) {
                height: 80px;
            }

            @include media-breakpoint-up(xxl) {
                height: 176px;
            }
        }
    }

    &-blog {
        padding: 22px 0 50px 0;

        @include media-breakpoint-up(md) {
            padding: 95px 0 115px 0;
        }
    }

    &-blog-item {
        padding: 25px 0 50px 0;

        @include media-breakpoint-up(md) {
            padding: 40px 0 90px 0;
        }

        h3, h4, h5 {
            margin-top: 32px;
            margin-bottom: 16px;
        }

        h3 {
            font-size: 24px;
            @include media-breakpoint-up(lg) {
                font-size: 26px;
            }
        }

        h4 {
            font-size: 20px;
            @include media-breakpoint-up(lg) {
                font-size: 22px;
            }
        }

        h5 {
            font-size: 18px;
            @include media-breakpoint-up(lg) {
                font-size: 20px;
            }
        }

        a {
            color: $green400;
            &:hover {
                color: $green600;
            }
        }
    }

    &-no-gap-bottom-mobile {
        @include media-breakpoint-down(md) {
            padding-bottom: 0;
        }
    }
}