/* testimonial-content-box */

.testimonial-content-box {
    padding-bottom: 60px;

    @include media-breakpoint-up(sm) {
        padding-bottom: 30px;
    }

    @include media-breakpoint-up(md) {
        padding-bottom: 130px;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 230px;
    }

    &-sub-text {
        @include media-breakpoint-down(sm) {
            max-width: 65%;
        }
    }

    &-col-9 {
        width: 100%;

        @media (min-width: 455px) and (max-width: 767.98px) { 
            width: 75%;
        }
    }
}

.testimonial-content-box-img {
    @include media-breakpoint-down(md) {
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 35%;
    }

    @include media-breakpoint-down(sm) {
        max-width: 55%;
    }

    min-width: 228px;
    min-height: 244px;
}