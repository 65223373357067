/* LIST DASH */

.list-dash {
    display: grid;
    grid-gap: 5px;
}

.list-dash-item {
    @extend .d-flex,
    .align-items-start;

    &:before {
        content: '-';
        @extend .me-2,
        .flex-shrink-0;
    }
}