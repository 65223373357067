/* HERO ABOUT */

.hero-about {
    @extend .position-relative,
    .bg-yellow500,
    .pb-md-0;
    z-index: 10;
    padding: 24px 0 10% 0;

    @media (min-width: 450px) {
        padding: 24px 0 50% 0;
    }

    @include media-breakpoint-down(md) {
        font-size: 15px;
    }

    &:before {
        @extend .position-absolute,
        .end-0,
        .bottom-0,
        .bg-white;

        @include media-breakpoint-up(md) {
            content: '';
            width: 35%;
            height: 200px;
            z-index: -1;
        }

        @include media-breakpoint-up(xl) {
            width: 45%;
            height: 310px;
        }
    }
}

.hero-about__img-desktop {
    @extend .w-100;
    max-width: 499px;
}

.hero-about__img-mobile {
    @extend .position-absolute,
    .end-0,
    .bottom-0;
    width: 244px;
    transform: translateY(48px);
}

.hero-about-section {

    &-title {
        @include media-breakpoint-up(md) {
            min-height: 110px;
        }
    }

    &-icon-container {
        width: 50px;
        height: 50px;
        @include media-breakpoint-up(md) {
            width: 70px;
            height: 70px;
        }
    }

    &-team-img {
        width: 50px;
        height: 50px;
        @include media-breakpoint-up(md) {
            width: 69px;
            height: 52px;
        }
        
    }

    &-target-img {
        width: 43px;
        height: 43px;
        @include media-breakpoint-up(md) {
            width: 62px;
            height: 62px;
        }
    }
}