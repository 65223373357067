/* FAQ */

.faq {
  display: grid;
  grid-gap: 20px;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(1, 240px 1fr);
    grid-gap: 25px;
  }

  @include media-breakpoint-up(lg) {
    grid-gap: 40px;
  }

  @include media-breakpoint-up(xl) {
    grid-gap: 70px;
  }

  @include media-breakpoint-up(xxl) {
    grid-gap: 100px;
  }

  &:not(:last-of-type) {
    padding-bottom: 30px;
  }

  & + .faq {
    border-top: 1px solid #c6d0e1;
    padding-top: 30px;
  }

  :target {
    scroll-margin-top: 100px;
  }
}

.faq-title {
  @extend .m-0;
  font-size: 24px;
  letter-spacing: -.2px;

  @include media-breakpoint-up(md) {
    font-size: 26px;
  }
}

.faq-items {
  display: grid;
  grid-gap: 25px;
  margin-top: 5px;
}

.faq-item {
  display: flex;
  flex-direction: column;

  &.active {
    .faq-toggle {
      @extend .text-orange500,
      .icon-minus;
    }

    .faq-body {
      @extend .d-block;
    }
  }
}

.faq-toggle {
  @extend .text-primary,
  .font-secondary-semibold,
  .font-icons,
  .icon-plus,
  .position-relative;
  font-size: 20px;
  letter-spacing: -0.2px;
  padding-left: 30px;
  line-height: 22px;

  &:hover,
  &:focus,
  &:active {
    @extend .text-orange500;
  }

  @include media-breakpoint-up(md) {
    font-size: 22px;
  }

  &:before {
    @extend .position-absolute,
    .start-0,
    .text-primary,
    .lh-1;
  }

  &.active {
    @extend .text-orange500,
    .icon-minus;

    & ~ .faq-body {
      @extend .d-block;
    }
  }
}

.faq-body {
  @extend .d-none,
  .text-gray1500;
  margin-left: 30px;
  margin-top: 10px;
  letter-spacing: -0.2px;
}

.faq-body-grid {
  display: grid;
  grid-gap: 21px;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 23px;
  }
}

.faq-body-toggle-modal {
  @extend .position-relative,
  .overflow-hidden;
  z-index: 10;
  background-color: #F0F3F8;
  border: 1px solid #D5DBE8;
  border-radius: 10px;
  padding-top: 5px;

  &:after {
    content: '';
    @extend .position-absolute,
    .w-100,
    .h-100,
    .start-0,
    .top-0;
    z-index: 11;
    background-repeat: no-repeat;
    background-size: 68px;
    background-position: 50% 50%;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjgiIGhlaWdodD0iNjgiIHZpZXdCb3g9IjAgMCA2OCA2OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMzQiIGN5PSIzNCIgcj0iMzQiIGZpbGw9ImJsYWNrIiBmaWxsLW9wYWNpdHk9IjAuMSIvPgo8Y2lyY2xlIGN4PSIzNC4wMDAxIiBjeT0iMzQiIHI9IjIxLjYzNjQiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0zMy45OTk4IDYuMTgxNzZDMjguNDk3OSA2LjE4MTc2IDIzLjExOTYgNy44MTMyNyAxOC41NDQ5IDEwLjg3QzEzLjk3MDIgMTMuOTI2NyAxMC40MDQ3IDE4LjI3MTMgOC4yOTkxOSAyMy4zNTQ0QzYuMTkzNjkgMjguNDM3NSA1LjY0MjggMzQuMDMwOCA2LjcxNjE3IDM5LjQyN0M3Ljc4OTU0IDQ0LjgyMzIgMTAuNDM5IDQ5Ljc3OTkgMTQuMzI5NCA1My42NzA0QzE4LjIxOTggNTcuNTYwOCAyMy4xNzY2IDYwLjIxMDIgMjguNTcyOCA2MS4yODM2QzMzLjk2OSA2Mi4zNTcgMzkuNTYyMyA2MS44MDYxIDQ0LjY0NTQgNTkuNzAwNkM0OS43Mjg1IDU3LjU5NTEgNTQuMDczMSA1NC4wMjk2IDU3LjEyOTggNDkuNDU0OUM2MC4xODY1IDQ0Ljg4MDIgNjEuODE4IDM5LjUwMTkgNjEuODE4IDMzLjk5OTlDNjEuODA5OSAyNi42MjQ2IDU4Ljg3NjUgMTkuNTUzNiA1My42NjEzIDE0LjMzODRDNDguNDQ2MiA5LjEyMzI4IDQxLjM3NTIgNi4xODk4NSAzMy45OTk4IDYuMTgxNzZaTTQ2LjIzOTggMzUuMjEyTDI4LjY4NDYgNDcuNjMwOEMyOC40MzM4IDQ3LjgxMzkgMjguMTMwNyA0Ny45MTE1IDI3LjgyMDIgNDcuOTA5QzI3LjU4MTggNDcuOTA2MiAyNy4zNDcyIDQ3Ljg0ODQgMjcuMTM0NyA0Ny43NDAxQzI2Ljg5MjEgNDcuNjE1IDI2LjY4ODcgNDcuNDI1NSAyNi41NDY4IDQ3LjE5MjVDMjYuNDA0OCA0Ni45NTk0IDI2LjMyOTggNDYuNjkxNyAyNi4zMyA0Ni40MTg4VjIxLjU4MTFDMjYuMzMwMyAyMS4zMDc2IDI2LjQwNTkgMjEuMDM5NiAyNi41NDg0IDIwLjgwNjJDMjYuNjkxIDIwLjU3MjggMjYuODk1IDIwLjM4MzIgMjcuMTM4MSAyMC4yNThDMjcuMzgxMyAyMC4xMzI5IDI3LjY1NDIgMjAuMDc3IDI3LjkyNjkgMjAuMDk2NkMyOC4xOTk3IDIwLjExNjIgMjguNDYxOCAyMC4yMTA0IDI4LjY4NDYgMjAuMzY5TDQ2LjIzOTggMzIuNzg3OUM0Ni40MzMzIDMyLjkyNTMgNDYuNTkxMSAzMy4xMDcgNDYuNyAzMy4zMTc5QzQ2LjgwODkgMzMuNTI4NyA0Ni44NjU3IDMzLjc2MjYgNDYuODY1NyAzMy45OTk5QzQ2Ljg2NTcgMzQuMjM3MyA0Ni44MDg5IDM0LjQ3MTEgNDYuNyAzNC42ODJDNDYuNTkxMSAzNC44OTI5IDQ2LjQzMzMgMzUuMDc0NiA0Ni4yMzk4IDM1LjIxMloiIGZpbGw9IiNGRDc4MjMiLz4KPC9zdmc+Cg==");
  }

  &:before {
    content: '';
    @extend .position-absolute,
    .bottom-0,
    .start-0,
    .w-100;
    background-color: #387FA7;
    border-top: 1px solid #D5DBE8;
    z-index: -1;
    height: 39px;
  }
}

.faq-logo {
  @extend .d-block,
  .img-fluid,
  .position-absolute,
  .start-50,
  .translate-middle-x;
  max-width: 147px;
  top: 8px;
}