/* MODAL */

.modal {
    .btn-close {
        @extend .position-absolute,
        .shadow-none;
        right: 20px;
        top: 20px;
        opacity: 1;
        width: 15px;
        height: 15px;
        font-size: 12px;
    }
}

.modal-content {
    border-radius: 8px;
}

.modal-title {
    @extend .text-center,
    .flex-fill,
    .font-secondary-semibold;
    font-size: 16px;

    @include media-breakpoint-up(md) {
        font-size: 22px;
    }
}

.modal-header {
    @extend .position-relative;
}

.modal-header,
.modal-body,
.modal-footer {
    @include media-breakpoint-up(md) {
        padding-left: 31px;
        padding-right: 31px;
    }
}

.modal-body {
    @include media-breakpoint-up(md) {
        padding-bottom: 44px;
    }
}