/* GALLERY */

$gallery-btn-size: 50px;

.gallery-button-prev,
.gallery-button-next {
    width: $gallery-btn-size;
    height: $gallery-btn-size;
    @extend .rounded-circle,
    .font-icons,
    .d-flex,
    .align-items-center,
    .justify-content-center,
    .position-absolute,
    .top-50,
    .translate-middle-y,
    .bg-white;
    border-width: 1px;
    border-style: solid;
    z-index: 20;

    &.swiper-button-disabled {
        opacity: 0;
        visibility: hidden;
    }

    &--has-shadow {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
        border-color: $gray1800;
    }

    &--dark {
        border-color: $primary;
    }
}

.gallery-button-prev {
    @extend .icon-chevron-left;
    left: calc(-#{$gallery-btn-size} / 2);
}

.gallery-button-next {
    @extend .icon-chevron-right;
    right: calc(-#{$gallery-btn-size} / 2);
}