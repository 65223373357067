/* testimonials */

.testimonials-gallery {
    @include media-breakpoint-down(sm) {
        width: calc(100% + #{$global-space});
        // transform: translateX($global-space)
    }
}

.testimonial {
    @extend .font-base-medium;
    padding-right: 10px;
    padding-bottom: 20px;
    border-right: 1px solid $gray1800;
    font-size: 14px;

    @include media-breakpoint-up(sm) {
        padding-right: 40px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 18px;
    }
}

.testimonial-header {
    @extend .d-flex,
    .align-items-center;
    margin-bottom: 10px;
}

.testimonial-avatar {
    width: 50px;
    @extend .flex-shrink-0,
    .rounded-circle;
    margin-right: 14px;
}

.testimonial-name {
    @extend .font-secondary-bold;
    font-size: 19px;
    letter-spacing: -.2px;
}

.testimonial-rating {
    @extend .d-block,
    .w-100;
    max-width: 120px;
}