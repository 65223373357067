/* MODAL FAQ */

.modal-faq {
    .modal-dialog {
        @include media-breakpoint-up(md) {
            max-width: 625px;
        }
    }
}

.modal-faq-img {
    @extend .w-100;
    border: solid 1px #d5dbe8;
    border-radius: 10px;
}