/* BOOTSTRAP OVERRIDE */

.container {
    padding-left: $global-space;
    padding-right: $global-space;

    @include media-breakpoint-up(xxl) {
        max-width: 1140px;
    }

    @include media-breakpoint-down(md) {
        max-width: 100%;
    }

    .row {
        margin-left: -$global-space;
        margin-right: -$global-space;

        >* {
            padding-left: $global-space;
            padding-right: $global-space;
        }
    }
}

.container-xl {
    @extend .container;

    @include media-breakpoint-up(xxl) {
        padding-left: 160px;
        padding-right: 160px;
        max-width: 100%;
    }
}