/* PROCESS */

.process {
    display: grid;
    grid-gap: 35px;
    counter-reset: process;

    @include media-breakpoint-up(md) {
        grid-gap: 25px;
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(xl) {
        grid-gap: 45px;
        grid-template-columns: repeat(4, 1fr);
    }
}

.process-item {
    @extend .position-relative,
    .text-md-center,
    .d-flex,
    .flex-row,
    .flex-md-column;
    font-size: 12px;

    @include media-breakpoint-up(md) {
        font-size: 13px;
    }

    &:not(:last-child) {
        &:after {
            content: '';
            @extend .position-absolute;
            background-repeat: no-repeat;

            @include media-breakpoint-down(md) {
                top: 45px;
                width: 3px;
                height: 74%;
                // background: linear-gradient(to bottom, transparent 50%, $black 50%);
                // background-size: 3px 8px,
                //     100% 3px;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMyIgaGVpZ2h0PSI4OCIgdmlld0JveD0iMCAwIDMgODgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjUgODYuNUwxLjUgMS41IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWRhc2hhcnJheT0iNCA2Ii8+Cjwvc3ZnPgo=");
                left: 18px;
            }

            @include media-breakpoint-up(md) {
                right: 0;
                top: 22px;
                // background: linear-gradient(to right, transparent 50%, $black 50%);
                // background-size: 16px 5px,
                //     100% 5px;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMzIiBoZWlnaHQ9IjUiIHZpZXdCb3g9IjAgMCAxMzMgNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGxpbmUgeDE9IjIuNSIgeTE9IjIuNSIgeDI9IjEzMC41IiB5Mj0iMi41IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWRhc2hhcnJheT0iNCAxMiIvPgo8L3N2Zz4K");
                height: 5px;
                width: 133px;
                right: -87px;
            }
        }
    }

    &:nth-child(2) {
        @include media-breakpoint-between(md, xl) {
            &:after {
                content: none !important;
            }
        }
    }
}

.process-item-icon {
    @extend .text-black,
    .d-block,
    .flex-shrink-0,
    .mx-auto,
    .text-center;

    width: 40px;
    height: 40px;

    @include media-breakpoint-up(md) {
        width: 67px;
        height: 67px;
        margin-bottom: 30px;
    }
}

.kredit-item-icon {
    width: 38px;
    height: 38px;

    @include media-breakpoint-up(md) {
        width: 65px;
        height: 65px;
        margin-bottom: 30px;
    }

    &.icon-rect {
        @include media-breakpoint-up(md) {
            width: 90px;
            height: 65px;
            margin-bottom: 30px;
        }
    }
}

.process-item-title {
    font-size: 20px;
    letter-spacing: -0.2px;
    @extend .mb-2,
    .mb-md-3;

    &:before {
        counter-increment: process;
        content: counter(process) ". ";
    }

    @include media-breakpoint-up(md) {
        font-size: 22px;
    }
}