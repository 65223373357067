/* HERO */

.hero {
    @extend .position-relative,
    .pb-xxl-0;
    z-index: 11;

    @include media-breakpoint-up(xs) {
        padding-bottom: 20px;
    }

    @include media-breakpoint-up(md) {
        padding-top: 18px;
        padding-bottom: 24%;
    }

    @media (min-width: 875px) {
        padding-bottom: 22%;
    }

    @media (min-width: 910px) {
        padding-bottom: 20%;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 17%;
    }

    @include media-breakpoint-up(xl) {
        padding-bottom: 6%;
    }

    &:after {
        @include media-breakpoint-up(md) {
            content: '';
        }

        @extend .position-absolute,
        .end-0,
        .bottom-0;
        width: 45%;
        height: 260px;
    }

    &--home {
        &:after {
            @extend .bg-gray200;
        }
        &-mobile-img {
            @include media-breakpoint-down(md) {
                margin-top: -12px;
            }
        }
    }

    &--paterina {
        &-mobile-img {
            @include media-breakpoint-down(md) {
                margin-top: 7px;
            }
        }
    }

    &--white {
        &:after {
            @extend .bg-white;
        }
    }

    &--remontam {
        @include media-breakpoint-up(lg) {
            padding-bottom: 19%;
        }

        @include media-breakpoint-up(xl) {
            padding-bottom: 11%;
        }

        &-mobile-img {
            @include media-breakpoint-down(md) {
                margin-top: 7px;
            }
        }
    }
}

.hero-icons {
    @extend .d-block;
    width: 150px;

    @include media-breakpoint-up(md) {
        width: 170px;
    }
}

.list-check--hero {
    margin-bottom: 15px;

    @include media-breakpoint-up(md) {
        margin-bottom: 45px;
    }
}

.hero-img {
    @extend .position-relative;
    z-index: 20;

    @include media-breakpoint-up(xl) {
        width: calc(100% + 40px) !important;
    }

    @include media-breakpoint-up(xxl) {
        width: calc(100% + 120px) !important;
        &.img--index {
            margin-left: 100px;
            margin-top: 15px;
        }
        &.img--consumer {
            margin-left: 30px;
        }
        &.img--refinancing {
            margin-left: 30px;
        }
        &.img--car {
            margin-left: 10px;
        }
        &.img--realestate {
            margin-left: 15px;
        }
        &.img--collateral {
            margin-left: 25px;
        }
    }

    @include media-breakpoint-down(xs) {
        width: calc(100% + #{$global-space});
        &.img--index {
            margin-top: 15px;
        }
    }

    &.hero-404 {
        background: #ECF1E7;
        height: 380px;
        position: absolute !important;
        z-index: 1;
        width: 100% !important;
    }
}

.hero-title {
    line-height: 1.02;
    letter-spacing: -0.8px;
    font-size: 38px;

    @include media-breakpoint-down(md) {
        margin-bottom: 0;
        padding-top: 20px;
        font-size: 30px;
    }
    @include media-breakpoint-up(md) {
        padding-top: 38px;
    }

    &.ht-renovation {
        padding-top: 46px;
    }

    &.ht-collateral {
        padding-top: 67px;
    }

    &.ht-refinancing {
        padding-top: 23px;
    }

    &--sm {
        @include media-breakpoint-up(md) {
            font-size: 32px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 34px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 42px;
        }
    }
}