.editable-text {
  &:hover {
    cursor: pointer;
    color: red;
  }
}

.editable-footer-links {
  &:hover {
    background: blue;
    cursor: pointer;
  }
}

.form-control.is-invalid,
.was-validated .form-control:invalid,
.was-validated .form-control:valid {
  background-image: none;
}

.loader {
  background: url("../img/elements/ajax-loader.gif") no-repeat center;
  width: 71px;
  height: 71px;
  margin: 0 auto;
}

.img-404 {
  margin-top: 40px;
}

.content-404 {
  padding-bottom: 100px !important;
}

.creditlimit-btn-404 {
  margin-top: 100px;
  @include media-breakpoint-down(md) {
    margin-top: 0px;
    width: 100%;
  }
}

.pr-5px {
  padding-right: 5px !important;
}

.pl-5px {
  padding-left: 5px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.mb-30px {
  margin-bottom: 30px;
}

.mt-30px {
  margin-top: 30px;
}

.we-promise-image {
  transform: translateY(-20px);
}

.personal-data-text {
  table {
    width: 100%;
    margin-bottom: 8px;

    td {
      text-align: left;
      vertical-align: top;
      border-width: 1px;
      padding: 4px 8px;
    }
  }
}
