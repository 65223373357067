/* we promise */

.we-promise-title {
    font-size: 28px;
    line-height: 1.05;
    letter-spacing: -0.2px;
    margin-bottom: 50px;

    @include media-breakpoint-up(md) {
        font-size: 44px;
        margin-bottom: 70px;
    }
}

.we-promise-stats {
    display: grid;
    grid-column-gap: 40px;
    grid-row-gap: 30px;
    counter-reset: promise-stats;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;

    @include media-breakpoint-up(md) {
        grid-row-gap: 50px;
        grid-column-gap: 50px;
    }

    @include media-breakpoint-up(lg) {
        grid-column-gap: 80px;
        grid-row-gap: 80px;
    }

    @include media-breakpoint-up(xl) {
        grid-column-gap: 80px;
        grid-row-gap: 76px;
    }
}

.we-promise-stat {
    @include media-breakpoint-up(xxl) {
        padding-right: 25%;
    }

    &:before {
        @extend .font-secondary-black,
        .d-block,
        .mb-4;
        counter-increment: promise-stats;
        content: "0"counter(promise-stats);
        font-size: 55px;
        line-height: .5;
        letter-spacing: -.12px;

        @include media-breakpoint-up(md) {
            font-size: 100px;
            line-height: .46;
            letter-spacing: -.2px;
        }
    }
}

.we-promise-stat-title {
    font-size: 14px;
    line-height: 1.1;
    letter-spacing: -0.12px;

    @include media-breakpoint-up(md) {
        font-size: 26px;
        line-height: 1;
        letter-spacing: -0.2px;
    }
}